/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState } from "react"
import { ArrowSquareIn, Buildings, Check, X } from "@phosphor-icons/react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import {
  createOrganisation,
  searchOrganisationsByTerm,
} from "../../api/organisations/organisations"
import Button from "../common/Button"
import { timeAgo, timeAgoEs } from "../../utils/dates/dates"
import Modal from "../common/Modal"
import Input from "../common/Input"
import Loader from "../common/Loader"
import store from "../../store"

function NoOrganisations() {
  const { t } = useTranslation()
  return (
    <div className="flex w-full h-full justify-center items-center">
      <h2 className="text-gray-500 font-bold text-md w-full text-center px-8">
        {t("noOrganisationsWereFoundAddANewOrg")}
      </h2>
    </div>
  )
}

function OrganisationItem(props) {
  const {
    assets,
    org_name,
    org_date_added,
    org_is_demo,
    // n_aws,
    // n_ftp,
    // n_tld,
    org_is_enabled,
    org_id,
    org_group,
    org_is_monitored,
  } = props
  const totalAssetCount =
    Object.keys(assets).length === 0
      ? "0"
      : Object.keys(assets?.tld).length.toString()

  const { t, i18n } = useTranslation()

  const lastActive = org_date_added
    ? timeAgo.format(Date.parse(org_date_added))
    : t("noActivity")
  const lastActiveEs = org_date_added
    ? timeAgoEs.format(Date.parse(org_date_added))
    : t("noActivity")
  const updatedLastActive =
    i18n.resolvedLanguage === "es" ? lastActiveEs : lastActive

  const sample = org_is_demo ? "Demo" : "Live"

  const navigate = useNavigate()

  return (
    <tr
      onClick={() => navigate(`/dashboard/organisations/${org_id}`)}
      className="cursor-pointer hover:bg-slate-50 text-xs text-gray-500 p-4 border-b border-gray-100 w-full"
      key={org_id}
    >
      <td className="w-40 text-left pl-2">
        <div className="font-bold flex">
          {org_name}
          <ArrowSquareIn size={16} />
        </div>
        <div>{org_group}</div>
      </td>
      <td>
        <div>{updatedLastActive}</div>
      </td>
      <td>
        <div className="flex justify-center items-center">
          {totalAssetCount}
        </div>
      </td>
      <td>
        <div className="flex justify-center items-center">
          {org_is_monitored ? (
            <div className="p-[3px] bg-green-100 text-green-800 rounded-full mr-1">
              <Check size={9} />
            </div>
          ) : (
            <div className="p-[3px] bg-red-100 text-red-800 rounded-full mr-1">
              <X size={9} />
            </div>
          )}
        </div>
      </td>
      <td>
        <div className="flex justify-center items-center">
          {org_is_enabled ? (
            <div className="p-[3px] bg-green-100 text-green-800 rounded-full mr-1">
              <Check size={9} />
            </div>
          ) : (
            <div className="p-[3px] bg-red-100 text-red-800 rounded-full mr-1">
              <X size={9} />
            </div>
          )}
        </div>
      </td>
      <td>
        <div className="flex justify-center items-center">
          {sample ? (
            <div className="p-[3px] bg-green-100 text-green-800 rounded-full mr-1">
              <Check size={9} />
            </div>
          ) : (
            <div className="p-[3px] bg-red-100 text-red-800 rounded-full mr-1">
              <X size={9} />
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

export default function OrganisationsCard() {
  const initialOrganisations = useSelector((state) => state.organisations)
  const [organisations, setOrganisations] = useState(initialOrganisations)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [name, setName] = useState("")
  const [caseStudy, setCaseStudy] = useState("")
  const [reference, setReference] = useState("")
  const [tld, setTld] = useState("")
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const { t } = useTranslation()
  //   const unsubscribe = store.subscribe(handleChange)
  function select(state) {
    return state.organisations
  }

  let currentValue

  // eslint-disable-next-line no-unused-vars
  function handleChange() {
    const previousValue = currentValue
    currentValue = select(store.getState())
    if (previousValue !== currentValue) {
      setOrganisations(currentValue)
    }
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleFormSubmit = async () => {
    setModalLoading(true)
    try {
      await createOrganisation({ name, caseStudy, reference, tld })
      setModalLoading(false)
      closeModal()
    } catch (error) {
      setModalLoading(false)
    }
  }
  const handleOrganisationSearch = async (term) => {
    if (term.length < 3) return
    setLoading(true)
    setOrganisations([])
    const orgs = await searchOrganisationsByTerm(term)
    setOrganisations(orgs)
    setLoading(false)
  }

  return (
    <div className="flex flex-col h-full w-full relative pb-[35px]">
      <Input
        placeholder={t("searchOrganisations")}
        className="m-4"
        onChange={handleOrganisationSearch}
      />
      <div className="flex flex-col w-full flex-grow overflow-scroll pb-[35px]">
        {organisations && organisations.length ? (
          <table cellPadding="10">
            <tr className="text-xs text-gray-500 text-left bg-slate-50">
              <th />
              <th>{t("dateAdded")}</th>
              <th className="text-center">{t("totalAssets")}</th>
              <th className="text-center">{t("monitoring")}</th>
              <th className="text-center">{t("status")}</th>
              <th className="text-center">{t("demo")}</th>
            </tr>
            {organisations.map((o) => (
              <OrganisationItem {...o} />
            ))}
          </table>
        ) : (
          <NoOrganisations />
        )}
      </div>
      <div className="shadow-inner bg-white absolute bottom-[35px] w-full">
        <button
          type="button"
          onClick={openModal}
          className="p-2 text-xs text-gray-400 w-full text-center hover:bg-slate-50 transition-all hover:animate-pulse"
        >
          + {t("addNewOrganisation")}
        </button>
      </div>
      <Modal
        title={t("addNewOrganisation")}
        icon={<Buildings size={16} />}
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        {!modalLoading ? (
          <div className="p-4 space-y-4">
            <div>
              <Input
                onChange={setName}
                required
                id="name"
                type="text"
                placeholder={t("organisationName")}
              />
            </div>
            <div>
              <Input
                onChange={setCaseStudy}
                id="case"
                type="text"
                placeholder={t("caseStudy")}
              />
            </div>
            <div>
              <Input
                onChange={setReference}
                id="reference"
                type="text"
                placeholder={t("internalReference")}
              />
            </div>
            <div>
              <Input
                onChange={setTld}
                id="domain"
                type="text"
                placeholder={t("domain")}
              />
            </div>
            <Button
              disabled={!name}
              onClick={() => handleFormSubmit()}
              text={t("createOrganisation")}
            />
          </div>
        ) : (
          <div className="py-4">
            <Loader variant="dark" />
          </div>
        )}
      </Modal>
    </div>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"

function Footer() {
  const { t } = useTranslation()

  return (
    <div className="w-full">
      <div className="w-full mt-6 text-white text-xs">
        <a
          target="_blank"
          href={t("privacyLink")}
          className="text-white opacity-70 text-xs mr-1"
          rel="noreferrer"
        >
          {t("privacyLinkText")}
        </a>{" "}
        |{" "}
        <a
          target="_blank"
          href={t("termsLink")}
          className="text-white ml-1 opacity-70 text-xs"
          rel="noreferrer"
        >
          {t("termsLinkText")}
        </a>
      </div>
    </div>
  )
}

export default Footer

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          username: "Username",
          password: "Password",
          confirmPassword: "Confirm Password",
          logout: "Logout",
          forgotPassword: "Forgot password?",
          loggedInAs: "User successfully logged in as",
          register: "Register",
          addNewAsset: "Add new asset",
          addNewOrganisation: "Add new organisation",
          administration: "Administration",
          active: "Active",
          activeScans: "Active scans",
          alerts: "Alerts",
          althoughWeDidNotFindAnySeriousBreachesOfData:
            "although we did not find any serious leaks of data.",
          allowNotifications: "Allow notifications",
          analytics: "Analytics",
          asset: "Asset",
          assets: "Assets",
          back: "Back",
          breach: "leak",
          breaches: "leaks",
          caseStudy: "Case study",
          categories: "Categories",
          city: "City",
          cleanYourDocument: "Clean your document",
          cleaningYourDocument: "Cleaning your document",
          cleaningYourDocumentText:
            "We are now cleaning your document of exposed metadata, this should only take a few minutes.",
          changesSaved: "Changes saved",
          country: "Country",
          createAsset: "Create Asset",
          createOrganisation: "Create Organisation",
          dashboard: "Dashboard",
          dataExplorer: "Data Explorer",
          dateAdded: "Date Added",
          demo: "Demo",
          deleteAsset: "Delete asset",
          deleteOrganisation: "Delete organisation",
          disabled: "Disabled",
          docs: "Docs",
          document: "Document",
          documents: "Documents",
          documentSize: "Document size",
          documentSuccessfullyCleaned: "Document successfully cleaned",
          documentType: "Document type",
          documentsFound: "Documents found",
          domain: "Domain",
          domains: "Domains",
          downloadTime: "Download time",
          downloadYourDocument: "Download your document",
          enterDomain: "Enter domain",
          enterDetailsBelowToCreateAnAccount:
            "Enter your details below to create an account",
          enableDisableAsset: "Enable/disable asset",
          enableDisableMonitoring: "Enable/disable monitoring",
          enableDisableOrganisation: "Enable/disable organisation",
          explore: "Explore",
          exposedValue: "Exposed value",
          emailAddress: "Email Address",
          excludeAThreat: "Exclude a threat",
          excludeAFiletype: "Exclude a filetype",
          filename: "Filename",
          fileUploaderLabel: "Upload or drop a file right here",
          filterBy: "Filter by",
          gpsCoordinates: "GPS co-ordinates",
          highlySignificant: "highly significant",
          internalReference: "Internal reference",
          including: "including",
          ipAddress: "IP Address",
          lastUpdate: "Last update",
          latestScan: "Latest Scan",
          launchScan: "Launch scan",
          live: "Live",
          locations: "Locations",
          locationDataNotFound: "No location data was found",
          login: "Login",
          loginHere: "login here",
          loginText:
            "Welcome back, enter your username and password below to login.",
          resetPassword: "Reset Password",
          manualScan: "Manual Scan",
          metadataItems: "Metadata items",
          monitor: "Monitor",
          monitoring: "Monitoring",
          noActivity: "No Activity",
          noAssetsFound: "No assets found",
          noAssetsWereFound: "No assets were found",
          noDataWasFoundToExplore: "No data was found to explore",
          noDateFound: "No date found",
          noOrganisationsWereFoundAddANewOrg:
            "No organisations were found, add a new organisation below.",
          noScanDataFound: "No scan data found",
          noScansWereFound: "No scans were found",
          noThreatDataFound: "No threat data found",
          noThreatDataWasFound: "No threat data was found",
          noLocationDataFound: "No location data found",
          noUpdateFound: "No update found",
          numberOfAssets: "Number of assets",
          or: "or",
          observability: "Observability",
          organisation: "Organisation",
          organisations: "Organisations",
          organisationName: "Organisation name",
          overallScore: "Overall score",
          otherPotentialBreaches: "other potential leaks",
          privacy: `I understand and accept that Suments Data Ltd will use my personal data in accordance with:`,
          termsLinkText: "Terms & Conditions",
          passwordLengthShouldBe:
            "Password length should be 8 characters or more",
          personalNames: "Personal names",
          pleaseEnterAValidDomain: "Please enter a valid domain",
          pleaseEnterYourEmailBelowToReset:
            "Please enter your email below to reset your password.",
          privacyLinkText: "Privacy Policy",
          termsLink: "https://suments.com/en/terms-and-conditions/",
          privacyLink:
            "https://suments.com/en/privacy-and-data-protection-policy/",
          potentiallySeriousBreaches: "potentially serious leaks",
          processingTime: "Processing time",
          registerAnAccount: "register an account",
          sample: "Sample",
          scan: "Scan",
          scans: "Scans",
          scanAdded: "Scan Added",
          scanAndClean: "Scan & Clean",
          scanningYourDocument: "Scanning your document",
          scanningYourDocumentText:
            "We are now scanning your document for exposed metadata, this should only take a few minutes.",
          search: "Search",
          searchAssets: "Search assets",
          searchScans: "Search scans",
          searchOrganisation: "Search organisation",
          searchOrganisations: "Search organisations",
          selectAThreatToFilterBy: "Select a threat to filter by",
          selectAFileTypeToFilterBy: "Select a file type to filter by",
          selectOrganisation: "Select organisation",
          settings: "Settings",
          significantBreaches: "Significant leaks",
          size: "Size",
          status: "Status",
          tags: "Tags",
          threat: "Threat",
          threats: "Threats",
          toDownloadYourCleanedFileYouWillNeedTo:
            "To download your cleaned file you will need to",
          tool: "Tool",
          tools: "Tools",
          totalAssets: "Total Assets",
          totalMetadata: "Total Metadata",
          totalThreats: "Total threats",
          totalScans: "Total scans",
          type: "Type",
          uncoverDataVulnerabilities: "Uncover data vulnerabilties",
          uncoverDataVulnerabilitiesText:
            "Effortlessly evaluate data exposure by uploading a document for thorough analysis.",
          uniqueMetadata: "Unique Metadata",
          uploadDocument: "Upload a document",
          uploadYourDocument: "Upload your document",
          uploadYourDocumentText:
            "Choose a document you would like us to analyse for metadata leaks.",
          uploadAnotherDocument: "Upload another document",
          userSucessfullyRegistered: "User successfully registered",
          value: "Value",
          values: "Values",
          weHaveIdentified: "We have identified",
          whatWeFound: "What we found",
          weHaveSuccessfullyCleanedText:
            "We have cleaned all sensitive and significant leaks from your document, you can download the new file below.",
        },
      },
      es: {
        translation: {
          username: "Nombre de usuario",
          password: "Contraseña",
          confirmPassword: "Confirmar Contraseña",
          logout: "Cerrar sesión",
          forgotPassword: "Contraseña olvidada",
          loggedInAs: "Logeado como",
          register: "Registro",
          addNewAsset: "Añadir recurso web",
          addNewOrganisation: "Añadir organización",
          administration: "Administración",
          active: "Activo",
          activeScans: "Escaneos activos",
          alerts: "Alertas",
          althoughWeDidNotFindAnySeriousBreachesOfData:
            "aunque no encontramos ninguna filtración grave de datos.",
          allowNotifications: "ES translation needed",
          analytics: "Análisis",
          asset: "Recurso web",
          assets: "Recursos web",
          back: "Atrás",
          breach: "Filtración",
          breaches: "Filtraciones",
          caseStudy: "Caso de estudio",
          categories: "ES translation needed",
          city: "Ciudad",
          cleanYourDocument: "Elimina los metadatos sensibles de tu documento",
          cleaningYourDocument: "Eliminando metadatos sensibles",
          cleaningYourDocumentText: "Eliminando metadatos sensibles",
          changesSaved: "ES translation needed",
          country: "País",
          createAsset: "Añadir recurso web",
          createOrganisation: "Añadir organización",
          dashboard: "Panel de control",
          dataExplorer: "Explorador de datos",
          dateAdded: "",
          demo: "Demo",
          deleteAsset: "Eliminar recurso web",
          deleteOrganisation: "Eliminar organización",
          disabled: "Deshabilitar",
          docs: "Documentos",
          document: "Documento",
          documents: "Documentos",
          documentsFound: "Documentos encontrados",
          documentSize: "Tamaño del documento",
          documentSuccessfullyCleaned: "Documentos limpiados con éxito",
          documentType: "Tipo de documento",
          domain: "Domino",
          domains: "Dominios",
          downloadTime: "Tiempo de descarga",
          downloadYourDocument: "Descargar el documento",
          enterDomain: "Introducir un domino. Ej: https://www.suments.com",
          enterDetailsBelowToCreateAnAccount: "ES translation needed",
          enableDisableAsset: "Habilitar/deshabilitar recurso web",
          enableDisableMonitoring: "Habilitar/deshabilitar monitorización",
          enableDisableOrganisation: "Habilitar/deshabilitar organización",
          explore: "Explorar",
          exposedValue: "",
          emailAddress: "Email",
          excludeAThreat: "Excluir amenaza",
          excludeAFiletype: "ES translation needed",
          filename: "Nombre de archivo",
          fileUploaderLabel: "Subir archivo",
          filterBy: "Filtrar por",
          gpsCoordinates: "ES translation needed",
          highlySignificant: "",
          internalReference: "Referencia interna",
          including: "Incluir",
          ipAddress: "Dirección IP",
          lastUpdate: "Última actualización",
          latestScan: "Último escáner",
          launchScan: "Escanear ahora",
          live: "",
          locations: "Ubicaciones",
          locationDataNotFound: "No se han encontrado datos geográficos",
          login: "Acceso",
          loginHere: "ES translation needed",
          loginText: "Login",
          resetPassword: "Restablecer la contraseña",
          manualScan: "Escánes",
          metadataItems: "ES transltion needed",
          monitor: "Monitorizar",
          monitoring: "Monitorizando",
          noActivity: "Sin actividad",
          noAssetsWereFound: "ES translation needed",
          noAssetsFound: "No se encontraron recursos web",
          noDataWasFoundToExplore: "No se encontraron datos.",
          noDateFound: "",
          noOrganisationsWereFoundAddANewOrg: "ES translation needed",
          noScanDataFound: "No se encontraton datos del escáner",
          noScansWereFound: "No se encontró un",
          noThreatDataFound: "No se encontraron datos de amenazas",
          noThreatDataWasFound: "No se encontraron datos de amenazas",
          noLocationDataFound: "No se han encontrado datos geográficos",
          noUpdateFound: "Sin actualizaciones",
          numberOfAssets: "Nº de recursos web",
          or: "o",
          observability: "",
          organisation: "Organización",
          organisations: "Organizaciones",
          organisationName: "Nombre de la organización",
          overallScore: "Puntuación total",
          otherPotentialBreaches: "Otras potenciales filtraciones",
          privacy:
            "Entiendo y acepto que Suments Data Ltd utilizará mis datos personales de acuerdo con su:",
          termsLinkText: "Terminos y condiciones",
          passwordLengthShouldBe: "ES translation needed",
          personalNames: "ES translation needed",
          pleaseEnterAValidDomain:
            "Introduzca un dominio válido. Ej: https://www.suments.com",
          pleaseEnterYourEmailBelowToReset: "ES translation needed",
          privacyLinkText: "Privacidad y proteccion de datos",
          processingTime: "Tiempo de procesado",
          termsLink: "https://suments.com/es/terminos-y-condiciones/",
          privacyLink:
            "https://suments.com/es/privacidad-y-proteccion-de-datos/",
          potentiallySeriousBreaches: "",
          processingTIme: "",
          registerAnAccount: "Registrar una cuenta",
          sample: "Muestra",
          scan: "Escáner",
          scans: "Escaneos",
          scanAdded: "Escáneo añadido",
          scanAndClean: "Escanear y limpiar",
          scanningYourDocument: "Escaneando el documento",
          scanningYourDocumentText: "Escaneando el documento",
          search: "Buscar",
          searchAssets: "Buscar recurso web",
          searchScans: "Buscar escáner",
          searchOrganisation: "Buscar organización",
          searchOrganisations: "Buscar organizaciones",
          selectAThreatToFilterBy: "Filtrar por amenaza",
          selectAFileTypeToFilterBy: "Filtrar por tipo de archivo",
          selectOrganisation: "Seleccionar organización",
          settings: "Opciones",
          significantBreaches: "Filtraciones significativas",
          size: "Tamaño",
          status: "Estado",
          tags: "Etiquetas",
          threat: "Amenaza",
          threats: "Amenazas",
          toDownloadYourCleanedFileYouWillNeedTo: "",
          tool: "Herramienta",
          tools: "Herramientas",
          totalAssets: "Nº de recursos web",
          totalMetadata: "Nº de metadatos encontrados",
          totalThreats: "Nº de amenazas asociadas",
          totalScans: "Nº de escaneos",
          type: "tipo",
          uncoverDataVulnerabilities: "",
          uncoverDataVulnerabilitiesText: "",
          uniqueMetadata: "Nº de metadatos únicos",
          uploadDocument: "Subir un documento",
          uploadYourDocument: "Subir un documento",
          uploadYourDocumentText: "Subir un documento",
          uploadAnotherDocument: "Subir otro",
          userSucessfullyRegistered: "ES translation needed",
          value: "Valor",
          values: "Valores",
          weHaveIdentified: "Hemos identificado",
          whatWeFound: "Encontramos",
          weHaveSuccessfullyCleanedText:
            "Hemos eliminado la información sensible y personal de los metadatos de los archivos.",
        },
      },
    },
  })

export default i18n

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import store from "../../store"

function NoThreats() {
  const { t } = useTranslation()
  return (
    <div className="flex w-full h-full justify-center items-center">
      <h2 className="text-gray-500 font-bold text-md w-full text-center px-8">
        {t("noThreatDataWasFound")}
      </h2>
    </div>
  )
}

export default function ThreatsCard() {
  //   const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [threats, setThreats] = useState([])
  //   const unsubscribe = store.subscribe(handleChange)

  function select(state) {
    return state
  }

  let currentAssetsValue
  // eslint-disable-next-line no-unused-vars
  function handleChange() {
    const previousAssetsValue = currentAssetsValue
    currentAssetsValue = select(store.getState())
    if (previousAssetsValue !== currentAssetsValue) {
      setThreats(currentAssetsValue.threats)
    }
  }

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex flex-row w-full flex-grow overflow-scroll max-h-[100%]">
        <NoThreats />
      </div>
    </div>
  )
}

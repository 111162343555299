import { createSlice } from "@reduxjs/toolkit"

export const organisationSlice = createSlice({
  name: "organisations",
  initialState: [],
  reducers: {
    update: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state = [...action.payload]
      return state
    },
  },
})

export const { update } = organisationSlice.actions

export default organisationSlice.reducer

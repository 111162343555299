/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  ArrowUDownLeft,
  Binoculars,
  MapTrifold,
  Scan,
} from "@phosphor-icons/react"
import { useTranslation } from "react-i18next"
import { getResultsByScanId } from "../../api/results/results"
import { LocationsCard } from "../../components/cards/LocationsCard"
import { DataExplorerCard } from "../../components/cards/DataExplorerCard"
import { getScanById } from "../../api/scans/scans"

function ScanSummary() {
  const { id } = useParams()
  const { t } = useTranslation()
  const [results, setResults] = useState({})
  const [mockScore, setMockScore] = useState(0)
  const [scan, setScan] = useState(undefined)
  const [scanDomain, setScanDomain] = useState("")
  const retrieveNewScan = async () => {
    if (!scan) {
      const retrievedScan = await getScanById(id)
      setScanDomain(retrievedScan[0].tld_norm)
      setScan(retrievedScan)
    }
  }

  retrieveNewScan()

  useEffect(() => {
    if (mockScore === 0) {
      setTimeout(() => {
        setMockScore(Math.floor(Math.random() * 100) + 1)
      }, 1500)
    }
  }, [])

  const getScanResults = async () => {
    try {
      const response = await getResultsByScanId(id)
      setResults(response.message[0])
    } catch (error) {
      console.log("Error when retrieving organisations", error)
    }
  }

  useEffect(() => {
    getScanResults()
  }, [scan, id])

  const navigate = useNavigate()
  return (
    <>
      {scan ? (
        <div className="flex flex-col flex-grow w-full h-full p-4 space-y-2">
          <div>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="flex uppercase text-xs text-slate-400"
            >
              <ArrowUDownLeft className="p-1" size={18} />
              {t("back")}
            </button>
          </div>
          <div>
            <h1 className="text-lg text-slate-600 flex items-center space-x-2">
              <Scan />
              <span>
                {scanDomain} (#{id})
              </span>
            </h1>
          </div>
          <hr />
          <div className="grid grid-cols-12 space-x-4">
            <div className="col-span-12 space-y-4">
              <div className="flex text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-x-4 items-start justify-start">
                <div className="h-[120px] w-[95px]">
                  <h2 className="text-center text-slate-600">
                    {t("overallScore")}
                  </h2>
                  <div
                    className={`circle percentage-${mockScore === 0 ? "10" : mockScore} scale-150 p-4 ${mockScore === 0 ? "animate-spin" : "animate-bounce"}}`}
                  >
                    <span>{mockScore === 0 ? "-" : mockScore}</span>
                    <div
                      className={`percentage-bar ${mockScore === 0 ? "animate-spin" : "animate-pulse"}`}
                    />
                  </div>
                </div>
                <div className="flex flex-col h-full text-center">
                  <h2 className="text-center mb-10 text-slate-600">
                    {t("totalMetadata")}
                  </h2>
                  <span className="text-[60px]">
                    {results.mv_total ? results.mv_total : "-"}
                  </span>
                </div>
                <div className="flex flex-col h-full text-center">
                  <h2 className="text-center mb-10 text-slate-600">
                    {t("uniqueMetadata")}
                  </h2>
                  <span className="text-[60px]">
                    {results.mv_total_unique ? results.mv_total_unique : "-"}
                  </span>
                </div>
                <div className="flex flex-col h-full text-center">
                  <h2 className="text-center mb-10 text-slate-600">
                    {t("documentsFound")}
                  </h2>
                  <span className="text-[60px]">
                    {results.doc_total_unique ? results.doc_total_unique : "-"}
                  </span>
                </div>
              </div>
              <div className="flex flex-col text-xs justify-between shadow-md text-slate-400 bg-white space-y-2 items-start justify-start">
                <h1 className="text-lg text-slate-600 flex items-center p-4 pb-0 space-x-2">
                  <Binoculars />
                  <span>{t("dataExplorer")}</span>
                </h1>
                <DataExplorerCard scanId={id} />
              </div>
              <div className="flex flex-col text-xs justify-between shadow-md text-slate-400 bg-white space-y-2 items-start justify-start h-full">
                <h1 className="text-lg text-slate-600 flex items-center space-x-2 p-4">
                  <MapTrifold />
                  <span>{t("locations")}</span>
                </h1>
                <LocationsCard scanId={id} mapHeight="40vh" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default ScanSummary

import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./user"
import assetsReducer from "./assets"
import organisationsReducer from "./organisations"
import scansReducer from "./scans"
import groupsReducer from "./groups"

export default configureStore({
  reducer: {
    user: userReducer,
    assets: assetsReducer,
    organisations: organisationsReducer,
    scans: scansReducer,
    groups: groupsReducer,
  },
})

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/order */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { getLatestDocuments, getLatestMetavalues } from "../../api/results/results";
import { filesize } from "filesize";
import { ArrowCircleRight, ArrowSquareIn, ArrowsClockwise, Eraser } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import store from "../../store";
import Loader from "../common/Loader";
import ReactPaginate from 'react-paginate';
import { searchOrganisations } from "../../api/organisations/organisations";
import { useTranslation } from "react-i18next";
import { filter } from "lodash";

const NoData = () => {
    const { t } = useTranslation()
    return (
        <div className="flex w-full h-full justify-center items-center">
            <h2 className="text-gray-500 font-bold text-md w-full text-center px-8 pb-4">{t('noDataWasFoundToExplore')}</h2>
        </div>
    )
}

const DataItem = (props) => {

    const { mv_value, fs_filetype, doc_size, dom_name, doc_filename, doc_url, mv_id_scan, hasScanId } = props;

    const navigate = useNavigate()

    return <tr className="text-xs hover:bg-slate-100 w-full">
        <td>
            <div onClick={() => window.open(`http://${doc_url}`, "_blank")} className="overflow-hidden w-full font-bold text-slate-500 flex cursor-pointer"><span>{doc_filename}</span> <span><ArrowSquareIn size={16} /></span></div>
            <span className="overflow-hidden w-full text-slate-400 flex">{dom_name}</span>
        </td>
        <td>
            <span className="overflow-hidden w-full font-bold text-slate-500 flex">{mv_value}</span>
        </td>
        <td>
            <span className="text-slate-500 flex">{fs_filetype}</span>
        </td>
        <td>
            <span className="text-slate-500 flex">{filesize(doc_size)}</span>
        </td>
        <td>
            <span className="overflow-hidden w-full text-slate-500 flex">{dom_name}</span>
        </td>
        {!hasScanId ? <td>
            <span className="text-slate-500 cursor-pointer" onClick={() => navigate(`/dashboard/scans/${mv_id_scan}`)}><ArrowCircleRight size={16} /></span>
        </td> : ''}
    </tr>
}


export const DataExplorerCard = ({ scanId = '' }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([])
    const [groupFilter, setGroupFilter] = useState([])
    const [organisationFilter, setOrganisationFilter] = useState(0)
    const [excludeGroupFilter, setExcludeGroupFilter] = useState([])
    const [excludeFiletypeFilter, setExcludeFiletypeFilter] = useState([])
    const [fileTypeFilter, setFileTypeFilter] = useState([])
    const [searchFilter, setSearchFilter] = useState('')
    const [organisations, setOrganisations] = useState([])
    const [groups, setGroups] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [itemOffset, setItemOffset] = useState(0)

    const itemsPerPage = scanId ? 20 : 100;

    const baseSelectStyle = {     
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: 'rgb(226, 232, 240)',
            borderRadius: '0.6em',
            // outline: state.isFocused ? 'none' : 'none'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: 'rgb(148 163 184)',
            // outline: 'none'
        })
    }
    const getFiletypes = async() => {
        const fileTypes = []
        const documents = await getLatestDocuments()
        Object.keys(documents.message).forEach((key) => {
            fileTypes.push({ label: documents.message[key].fs_filetype, value: documents.message[key].fs_id});
        })

        setDocumentTypes(fileTypes);
        console.log("documents retrieved") 
}
    

    const unsubscribe = store.subscribe(handleChange)

    function select(state) {
        return state
    }
      
    let currentAssetsValue

    function handleChange() {
        let previousAssetsValue = currentAssetsValue
        currentAssetsValue = select(store.getState())

        let groupsArray = []
        let orgsArray = []
    
        if (previousAssetsValue !== currentAssetsValue) {

            currentAssetsValue.groups.forEach((g) => {
                groupsArray.push({ label: g.pc_metagroup_name, value: g.pc_id_metagroup})
            })

            currentAssetsValue.organisations.forEach((o) => {
                orgsArray.push({ label: o.org_name, value: o.org_id})
            })

            setGroups(groupsArray)
            setOrganisations(orgsArray)
        }
    }

  
   

    const setOrganisationsFilter = (orgFilter) => {
        setOrganisationFilter(orgFilter.value)
    }

    const getResults = async() => {
       
        try {
            setData([])
            setLoading(true)

            let groups = []
            groupFilter.forEach((f) => groups.push(f.value))

            let filetypes = []
            fileTypeFilter.forEach((f) => filetypes.push(f.value))
            

            const filterObject = {
                "org_id": organisationFilter !== "0" && organisationFilter,
                "scan_id": scanId,
                "include_group_id": groups,
                "include_filetype_id": filetypes, 
                "exclude_filetype_id": excludeFiletypeFilter,         
                "offset": itemOffset, 
                "limit": itemsPerPage
            }          
           
            const results = await getLatestMetavalues(filterObject)
            
            setTotalItems(results.message[0].doc_total_unique)
            setData(Object.keys(results.payload).map((key) => results.payload[key]))
            
            setLoading(false)
        } catch (error) {
            console.log('Error retrieving latest results', error)
            setData([])
            setLoading(false)

        }
    }
    useEffect(() => {
        getFiletypes()
}, [])

    useEffect(() => {
        if((!organisationFilter && !fileTypeFilter.length && !groupFilter.length && !itemOffset && !excludeGroupFilter.length && !excludeFiletypeFilter.length && !searchFilter && !scanId)) {
            setLoading(false)
        } else {
            setLoading(true)
            getResults()
            
        }
    }, [organisationFilter, fileTypeFilter, groupFilter, itemOffset, excludeGroupFilter, excludeFiletypeFilter, searchFilter])

   

    const handleFiletypeChange = (change) => {
        console.log(pageCount)
        setFileTypeFilter(change)
    }

    const handleGroupChange = (change) => {
        setGroupFilter(change)
    }

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = data.slice(itemOffset, endOffset);
    // issue with pagination? 
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage);
        setItemOffset(newOffset);
    };

    const handleExcludeGroupChange = (change) => {
        let groups = []
        change.forEach((f) => groups.push(f.value))
        setExcludeGroupFilter(groups)
    }
    const handleExcludeFiletypeChange = (change) => {
        let filetypes = []
        change.forEach((f) => filetypes.push(f.value))
        setExcludeFiletypeFilter(filetypes)
    }

    const clearQuery = () => {
        setItemOffset(0)
        setFileTypeFilter([])
        setOrganisationFilter([])
        setGroupFilter([])
        setExcludeGroupFilter([])
        setExcludeFiletypeFilter([])
        setSearchFilter('')
    }

    const setNewOrganisations = async(input, callback) => {
        
        const { payload } = await searchOrganisations(input)    
        setOrganisationsFilter([])

        let orgsArray = []

        Object.keys(payload ?? {}).forEach((o) => {
            orgsArray.push({ label: payload[o].org_name, value: payload[o].org_id})
        })
        setOrganisations(orgsArray) 
        callback(orgsArray)
    }
        const { t } = useTranslation()

    return (
        <div className="flex flex-col h-full w-full">
            <div className="flex flex-col w-full flex-grow overflow-scroll max-h-[100%] max-w-[100%]">
            <div className="pagination pagination-wrapper cursor-not-allowed">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onClick={() => {
                            if(loading) return false
                        }}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={20}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={false}
                        className={`${loading ? 'opacity-20 cursor-not-allowed' : ''}`}
                    />
                </div>
                <div className="flex items-center space-x-2 p-3">
                    <span className="font-bold text-xs text-slate-500">{t('filterBy')}:</span>
                    {!scanId ? <AsyncSelect 
                        defaultOptions={organisations && organisations.length ? organisations : []}
                        styles={baseSelectStyle}
                        isSearchable
                        cacheOptions={true}
                        loadOptions={setNewOrganisations}
                        onChange={setOrganisationsFilter}
                        className="text-xs border-slate-200 text-slate-400 w-auto min-w-[140px] focus-none outline-none"
                        placeholder={t('searchOrganisation')}
                        /> : ''}
                    <Select 
                        options={groups && groups.length ? groups : []}
                        styles={baseSelectStyle}
                        value={groupFilter}
                        isMulti
                        onChange={handleGroupChange}
                        className="text-xs border-slate-200 text-slate-400 w-auto min-w-[140px] focus-none outline-none"
                        placeholder={t('selectAThreatToFilterBy')}
                        />
                        <Select         
                        options={groups && groups.length ? groups : []}
                        styles={baseSelectStyle}
                        isMulti
                        onChange={handleExcludeGroupChange}
                        className="text-xs border-slate-200 text-slate-400 w-auto min-w-[140px] focus-none outline-none"
                        placeholder={t('excludeAThreat')}
                        />
                    <Select         
                        options={documentTypes && documentTypes.length ? documentTypes : []}
                        styles={baseSelectStyle}
                        value={fileTypeFilter}
                        isMulti
                        onChange={handleFiletypeChange}
                        className="text-xs border-slate-200 text-slate-400 w-auto min-w-[140px] focus-none outline-none"
                        placeholder={t('selectAFileTypeToFilterBy')}
                        />
                    <Select         
                        options={documentTypes && documentTypes.length ? documentTypes : []}
                        styles={baseSelectStyle}
                        isMulti
                        onChange={handleExcludeFiletypeChange}
                        className="text-xs border-slate-200 text-slate-400 w-auto min-w-[140px] focus-none outline-none"
                        placeholder={t('excludeAFiletype')}
                        />
                        <button onClick={() => getResults()} className="mr-2"><ArrowsClockwise className="text-slate-400" size={16} /></button>
                        <button onClick={() => clearQuery()} className="mr-2"><Eraser className="text-slate-400" size={16} /></button>
                </div>

                {loading ? <Loader /> : ''}

                {!loading && data && !data.length ? <NoData /> : 
                    <table className="w-full data-explorer" cellPadding={"10"}>
                    <thead className="text-xs text-gray-500 text-left bg-slate-50">
                        <th>{t('filename')}</th>
                        <th>{t('value')}</th>
                        <th>{t('documentType')}</th>
                        <th>{t('documentSize')}</th>
                        <th>{t('domain')}</th>
                        {!scanId ? <th>{t('scan')}</th> : ''}
                    </thead>
                    <tbody className={`${scanId ? 'block max-h-[300px] overflow-scroll' : 'block max-h-[100vh] overflow-scroll'}`}>
                        {data && data.length ? data.map((o) => <DataItem hasScanId={scanId} {...o} />) : ''}
                    </tbody>
            </table>}
            </div>
        </div>
    )

}
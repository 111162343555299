import { createSlice } from "@reduxjs/toolkit"

export const groupsSlice = createSlice({
  name: "groups",
  initialState: { groups: [] },
  reducers: {
    update: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state = [...action.payload]
      return state
    },
  },
})

export const { update } = groupsSlice.actions

export default groupsSlice.reducer

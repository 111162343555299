import React, { useState } from "react"
import { DndProvider, MultiBackend } from "react-dnd-multi-backend"
import { Scan } from "@phosphor-icons/react"
import DraggableGridItem from "../../utils/grid/DraggableGridItem"
import HTML5toTouch from "../../utils/grid/HTML5toTouch"
import ScansCard from "../../components/cards/ScansCard"

function Scans() {
  const initialItems = [
    {
      id: 1,
      index: 1,
      title: "Scans",
      content: <ScansCard />,
      icon: <Scan size={16} />,
    },
  ]

  // eslint-disable-next-line no-unused-vars
  const [list, setList] = useState(initialItems)
  const onDrop = () => {}
  return (
    <div className="flex flex-grow w-full h-full p-4">
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        {list.map((item) => (
          <DraggableGridItem
            key={item.id}
            item={item}
            onDrop={onDrop}
            title={item.title}
            icon={item.icon}
            fullWidth
          >
            {item.content}
          </DraggableGridItem>
        ))}
      </DndProvider>
    </div>
  )
}

export default Scans

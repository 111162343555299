import Stripe from "stripe"
import { subscriptionLevels } from "../../consts/subscriptions"
import { getKeyByValue, stripQuotes } from "../../utils"
import store from "../../store"
import { update } from "../../store/user"

const stripe = new Stripe(process.env.REACT_APP_STRIPE_API_KEY)

export const getSubscriptionLevels = async () =>
  stripe.prices.list({
    limit: 3,
  })

export const paymentLinks = {
  SCAN_ONLY: process.env.REACT_APP_SCAN_ONLY_CHECKOUT_LINK,
  SCAN_PRO: process.env.REACT_APP_SCAN_PRO_CHECKOUT_LINK,
  SCAN_PRO_CLEAN: process.env.REACT_APP_SCAN_PRO_CLEAN_CHECKOUT_LINK,
}

export const checkSubscription = async () => {
  await getSubscriptionLevels()

  try {
    const customer = await stripe.customers.list({
      email: stripQuotes(localStorage.getItem("s:User")),
    })

    if (customer.data.length) {
      const { subscriptions } = await stripe.customers.retrieve(
        customer.data[0].id,
        {
          expand: ["subscriptions"],
        },
      )
      if (subscriptions.data.length) {
        const subKey = getKeyByValue(
          subscriptionLevels,
          subscriptions.data[0].plan.id,
        )
        store.dispatch(update({ subscription: subKey }))
      }
    } else {
      return subscriptionLevels.NO_SUBSCRIPTION
    }
  } catch (error) {
    throw new Error(error)
  }
  return false
}

export const handleCheckout = async (email, priceId) => {
  try {
    const { error } = await stripe.checkout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: "subscription",
      customerEmail: email,
      successUrl: `${window.location.origin}/dashboard/plans`,
      cancelUrl: `${window.location.origin}/dashboard/plans`,
    })

    if (error) {
      throw new Error(error)
    }
  } catch (error) {
    throw new Error(error)
  }
}

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState } from "react"
import { ArrowSquareIn, Check, Clock, X } from "@phosphor-icons/react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { timeAgo, timeAgoEs } from "../../utils/dates/dates"
import Input from "../common/Input"
import Loader from "../common/Loader"
import store from "../../store"
import { searchScans } from "../../api/scans/scans"

function NoScans() {
  const { t } = useTranslation()
  return (
    <div className="flex w-full h-full justify-center items-center">
      <h2 className="text-gray-500 font-bold text-md w-full text-center px-8">
        {t("noScansWereFound")}
      </h2>
    </div>
  )
}

function ScanItem(props) {
  const { t, i18n } = useTranslation()
  const {
    scan_status = 200,
    scan_id,
    scan_date_last_update,
    scan_date_added,
    scan_date_started_download,
    scan_date_started_process,
    scan_date_finished_process,
    scan_date_finished_download,
    scan_added_by_user,
    tld_norm,
  } = props

  const added = scan_date_added
    ? timeAgo.format(Date.parse(scan_date_added))
    : t("noDataFound")
  const updated = scan_date_last_update
    ? timeAgo.format(Date.parse(scan_date_added))
    : t("noUpdateFound")
  const addedEs = scan_date_added
    ? timeAgoEs.format(Date.parse(scan_date_added))
    : t("noDataFound")
  const updatedEs = scan_date_last_update
    ? timeAgoEs.format(Date.parse(scan_date_added))
    : t("noUpdateFound")

  const addedTimeAgoInLanguageCheck =
    i18n.resolvedLanguage === "es" ? addedEs : added
  const updatedTimeAgoLanguageCheck =
    i18n.resolvedLanguage === "es" ? updatedEs : updated

  const downloadStarted = scan_date_started_download
    ? Date.parse(scan_date_started_download)
    : undefined
  const downloadEnded = scan_date_finished_download
    ? Date.parse(scan_date_finished_download)
    : undefined

  const processingStarted = scan_date_started_download
    ? Date.parse(scan_date_started_process)
    : undefined
  const processingEnded = scan_date_finished_download
    ? Date.parse(scan_date_finished_process)
    : undefined

  const timeToProcess = processingStarted - processingEnded
  const timeToDownload = downloadEnded - downloadStarted

  function padZero(n) {
    // eslint-disable-next-line prefer-template
    if (n < 10) return "0" + n
    return n
  }

  const downloadHours = padZero(new Date(timeToDownload).getHours())
  const downloadMinutes = padZero(new Date(timeToDownload).getMinutes())
  const processHours = padZero(new Date(timeToProcess).getHours())
  const processMinutes = padZero(new Date(timeToProcess).getMinutes())
  const navigate = useNavigate()

  return (
    <tr
      onClick={() => navigate(`/dashboard/scans/${scan_id}`)}
      className="cursor-pointer hover:bg-slate-50 text-xs text-gray-500 p-4 border-b border-gray-100 w-full"
      key={scan_id}
    >
      <td>
        <div className="flex justify-center items-center">
          {scan_status ? (
            <div className="p-[3px] bg-green-100 text-green-800 rounded-full mr-1">
              <Check size={9} />
            </div>
          ) : (
            <div className="p-[3px] bg-red-100 text-red-800 rounded-full mr-1">
              <X size={9} />
            </div>
          )}
        </div>
      </td>
      <td className="w-40 text-left pl-2">
        <div className="font-bold flex">
          {tld_norm} (#{scan_id}) <ArrowSquareIn size={16} />
        </div>
      </td>
      <td>
        <div>{addedTimeAgoInLanguageCheck}</div>
      </td>
      <td>
        {downloadEnded ? (
          <div>
            {downloadHours}:{downloadMinutes}
          </div>
        ) : (
          <Clock size={16} className="animate-spin" />
        )}
      </td>
      <td>
        {processingEnded ? (
          <div>
            {processHours}:{processMinutes}
          </div>
        ) : (
          <Clock size={16} className="animate-spin" />
        )}
      </td>
      <td>
        <div>{updatedTimeAgoLanguageCheck}</div>
      </td>
      <td>
        <div className="flex justify-center items-center">
          {scan_added_by_user ? (
            <div className="p-[3px] bg-green-100 text-green-800 rounded-full mr-1">
              <Check size={9} />
            </div>
          ) : (
            <div className="p-[3px] bg-red-100 text-red-800 rounded-full mr-1">
              <X size={9} />
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

export default function ScansCard() {
  const [loading, setLoading] = useState(false)
  const initialScans = useSelector((state) => state.scans)
  const [scans, setScans] = useState(initialScans)
  //   const unsubscribe = store.subscribe(handleChange)

  function select(state) {
    return state.scans
  }
  let currentValue
  // eslint-disable-next-line no-unused-vars
  function handleChange() {
    const previousValue = currentValue
    currentValue = select(store.getState())

    if (previousValue !== currentValue) {
      setScans(currentValue)
    }
  }

  const handleScanSearch = async (term) => {
    if (term.length < 3) return
    setLoading(true)
    setScans([])
    const scansData = await searchScans(term)
    setScans(scansData)
    setLoading(false)
  }
  const { t } = useTranslation()

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex flex-col w-full flex-grow overflow-scroll">
        <Input
          placeholder={t("searchScans")}
          className="m-4"
          onChange={handleScanSearch}
        />
        <div className="flex flex-col w-full flex-grow overflow-scroll">
          {scans && scans.length ? (
            <table cellPadding="10">
              <tr className="text-xs text-gray-500 text-left bg-slate-50">
                <th />
                <th>{t("asset")}</th>
                <th>{t("scanAdded")}</th>
                <th>{t("downloadTime")}</th>
                <th>{t("processingTime")}</th>
                <th>{t("lastUpdate")}</th>
                <th className="text-center">{t("manualScan")}</th>
              </tr>
              {scans && scans.length && scans.map((o) => <ScanItem {...o} />)}
            </table>
          ) : loading ? (
            <Loader />
          ) : (
            <NoScans />
          )}
        </div>
      </div>
    </div>
  )
}

import { createSlice } from "@reduxjs/toolkit"

export const assetsSlice = createSlice({
  name: "assets",
  initialState: { assets: [] },
  reducers: {
    update: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state = [...action.payload]
      return state
    },
  },
})

export const { update } = assetsSlice.actions

export default assetsSlice.reducer

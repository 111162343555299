import { DndProvider, MultiBackend } from "react-dnd-multi-backend"
import React, { useState } from "react"
import {
  Buildings,
  FloppyDiskBack,
  Radioactive,
  Scan,
} from "@phosphor-icons/react"
import { useTranslation } from "react-i18next"
import HTML5toTouch from "../../utils/grid/HTML5toTouch"
import Grid from "../../components/common/grid/Grid"
import DraggableGridItem from "../../utils/grid/DraggableGridItem"
import OrganisationsCard from "../../components/cards/OrganisationsCard"
import AssetsCard from "../../components/cards/AssetsCard"
import ScansCard from "../../components/cards/ScansCard"
import ThreatsCard from "../../components/cards/ThreatsCard"

const sortItems = (a, b) => a.index - b.index

function DashboardIndex() {
  const { t } = useTranslation()
  const initialItems = [
    {
      id: 1,
      index: 1,
      title: "organisations",
      content: <OrganisationsCard />,
      icon: <Buildings size={16} />,
    },
    {
      id: 2,
      index: 2,
      title: "assets",
      content: <AssetsCard />,
      icon: <FloppyDiskBack size={16} />,
    },
    {
      id: 3,
      index: 3,
      title: "scans",
      content: <ScansCard />,
      icon: <Scan size={16} />,
    },
    {
      id: 4,
      index: 4,
      title: "threats",
      content: <ThreatsCard />,
      icon: <Radioactive size={16} />,
    },
  ]

  const [list, setList] = useState(initialItems)
  const onDrop = (firstItemId, secondItemId) => {
    // changed from let to const for linting - doesn't appear to reassign vars
    const newList = [...list]
    const firstItem = newList.find((i) => i.id === firstItemId)
    const secondItem = newList.find((i) => i.id === secondItemId)
    const firstIndex = firstItem.index
    firstItem.index = secondItem.index
    secondItem.index = firstIndex
    setList(newList)
  }

  return (
    <div className="flex flex-grow w-full h-full p-4">
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <Grid>
          {list.sort(sortItems).map((item) => (
            <DraggableGridItem
              key={item.id}
              item={item}
              onDrop={onDrop}
              title={t(item.title)}
              icon={item.icon}
            >
              {item.content}
            </DraggableGridItem>
          ))}
        </Grid>
      </DndProvider>
    </div>
  )
}

export default DashboardIndex

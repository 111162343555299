/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react"
import { useDragAndDrop } from "./useDragAndDrop"
import LineLoader from "../../components/common/LineLoader"

const createDragHoverCallback =
  (ref, currentItem, onDrop) => (otherItem, monitor) => {
    const dragIndex = otherItem.index
    const hoverIndex = currentItem.index
    if (dragIndex === hoverIndex) {
      return
    }
    const hoverBoundingRect = ref.current.getBoundingClientRect()
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
    const clientOffset = monitor.getClientOffset()
    const hoverClientY = clientOffset.y - hoverBoundingRect.top
    const hoverClientX = clientOffset.x - hoverBoundingRect.right

    if (
      dragIndex < hoverIndex &&
      hoverClientY < hoverMiddleY &&
      hoverClientX < hoverMiddleX
    ) {
      return
    }

    if (
      dragIndex > hoverIndex &&
      hoverClientY > hoverMiddleY &&
      hoverClientX > hoverMiddleX
    ) {
      return
    }
    onDrop(otherItem.id, currentItem.id)
    // eslint-disable-next-line no-param-reassign
    otherItem.index = currentItem.index
  }

function DraggableGridItem({
  item,
  onDrop,
  children,
  icon,
  title,
  fullWidth,
  ...props
}) {
  const ref = useRef(null)
  const { isDragging } = useDragAndDrop(ref, {
    ...item,
    hover: createDragHoverCallback(ref, item, onDrop),
  })

  const opacity = isDragging ? 0 : 1

  const [searching, setSearching] = useState(true)

  setTimeout(() => {
    setSearching(false)
  }, 2500)

  return (
    <div
      className="shadow-sm rounded-md bg-white overflow-hidden w-full"
      {...props}
      ref={ref}
      style={{ opacity }}
    >
      <div
        className={`flex items-center text-xs text-gray-500 border-gray-100 ${!searching ? "border-b" : ""}`}
      >
        <span className="bg-cover p-2 rounded-tl-md">{icon}</span>
        <span className="flex-grow w-full font-bold">{title}</span>
      </div>
      {searching ? <LineLoader /> : ""}
      <div className="flex w-full h-full items-start">{children}</div>
    </div>
  )
}

export default DraggableGridItem

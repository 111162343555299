import React, { useState } from "react"
import { DndProvider } from "react-dnd"
import { MultiBackend } from "react-dnd-multi-backend"
import { Binoculars } from "@phosphor-icons/react"
import { useTranslation } from "react-i18next"
import HTML5toTouch from "../../utils/grid/HTML5toTouch"
import DraggableGridItem from "../../utils/grid/DraggableGridItem"
import { DataExplorerCard } from "../../components/cards/DataExplorerCard"

export default function DataExplorer() {
  const { t } = useTranslation()
  const initialItems = [
    {
      id: 1,
      index: 1,
      title: t("dataExplorer"),
      content: <DataExplorerCard />,
      icon: <Binoculars size={16} />,
    },
  ]

  // eslint-disable-next-line no-unused-vars
  const [list, setList] = useState(initialItems)

  return (
    <div className="flex flex-grow w-full h-full p-4">
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        {list.map((item) => (
          <DraggableGridItem
            key={item.id}
            item={item}
            onDrop={() => {}}
            title={item.title}
            icon={item.icon}
            fullWidth
          >
            {item.content}
          </DraggableGridItem>
        ))}
      </DndProvider>
    </div>
  )
}

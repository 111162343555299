/* eslint-disable react/prop-types */
import React from "react"

export default function Loader({ variant = "dark", center = true }) {
  const variantStyle = variant === "dark" ? "loader" : "loader-light"
  const alignment = center ? "margin-auto" : ""
  return (
    <div className={`${variantStyle} ${alignment}`}>
      <span />
    </div>
  )
}

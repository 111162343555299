import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import Button from "../common/Button"
import store from "../../store"
import { updateUser, getUser } from "../../api/users/users"

export default function SettingsCard() {
  const { t } = useTranslation()
  const baseSelectStyle = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderColor: "rgb(226, 232, 240)", // to be revisited/set elsewhere in the app
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "rgb(148 163 184)", // to be revisited/set elsewhere in the app
    }),
  }
  const [loading, setLoading] = useState(false)
  const [enableNotifications, setEnableNotifications] = useState(false)
  const [frequency, setFrequency] = useState("")
  const [showMessage, setShowMessage] = useState(false)
  function select(state) {
    return state.user
  }
  function handleNotificationChange() {
    setEnableNotifications(!enableNotifications)
  }
  function handleSelectFreq(e) {
    setFrequency(e[0].value.toString())
  }

  async function handleSaveNotifications() {
    const user = select(store.getState())
    const updatedNotifications = {
      data: {
        notifications: {
          recommendations: {
            enabled: enableNotifications,
            frequency,
          },
        },
      },
    }
    const response = await updateUser(updatedNotifications, user.email)
    if (response.ok) {
      setShowMessage(true)
      setTimeout(() => setShowMessage(false), 2000)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const userData = await getUser()
        setEnableNotifications(
          userData.data.notifications.recommendations.enabled,
        )
        setFrequency(userData.data.notifications.recommendations.frequency)
        setLoading(false)
      } catch (error) {
        console.error("Error fetching user data:", error)
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  const notificationOptions = [
    { value: "30", label: "30 days" },
    { value: "60", label: "60 days" },
  ]

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex flex-col w-full flex-grow p-4 overflow-scroll max-h-[90%] space-y-2 max-w-[400px]">
        {!loading && (
          <section className="flex flex-col">
            <div className="flex text-xs flex-row my-4 ml-4">
              <label htmlFor="allow-notifications">
                {t("allowNotifications")}
              </label>
              <input
                id="allow-notifications"
                checked={enableNotifications}
                onChange={() => handleNotificationChange()}
                className="mt-0.5 ml-8 h-3 w-3 border border-slate-300 rounded"
                type="checkbox"
              />
            </div>
            {enableNotifications && (
              <div className="flex text-xs flex-row my-4 ml-8">
                <Select
                  options={notificationOptions}
                  styles={baseSelectStyle}
                  value={frequency}
                  isMulti
                  onChange={(e) => handleSelectFreq(e)}
                  className="text-xs border-slate-200 text-slate-400 w-auto min-w-[140px] focus-none outline-none radius-md"
                  placeholder={
                    frequency ? `${frequency} days` : "Select frequency"
                  }
                />
              </div>
            )}
          </section>
        )}
        {showMessage && (
          <span className="text-xs font-bold text-gray-400 text-center my-4 pb-2">
            {t("changesSaved")}
          </span>
        )}
        <div className="flex max-w-[400px]">
          <Button
            onClick={() => handleSaveNotifications()}
            className="cursor-not-allowed"
            text="Save"
          />
        </div>
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable react/function-component-definition */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-use-before-define */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/self-closing-comp */
/* eslint-disable spaced-comment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { createOrganisation, getAllOrganisations, searchOrganisations } from "../../api/organisations/organisations"
import Button from "../common/Button";
import { timeAgo } from "../../utils/dates/dates";
import { ArrowCircleRight, ArrowSquareIn, Browsers, Buildings, Check, FileDoc, X } from "@phosphor-icons/react";
import Modal from "../common/Modal";
import Input from "../common/Input";
import Loader from "../common/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLatestDomains, getResultsByGroupId   } from "../../api/results/results";
import { validationLatitudeLongitude } from "validation-latitude-longitude";
import { filesize } from "filesize";
import {APIProvider, Map, AdvancedMarker, Pin, useAdvancedMarkerRef, InfoWindow} from '@vis.gl/react-google-maps';
import mapStyle from "../../config/maps";
import { setDefaultNamespace } from "i18next";
import { isLatitude, isLongitude } from "../../utils";
import ReactPaginate from "react-paginate";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import AsyncSelect from "react-select/async";
import store from "../../store";


export default function PhotoComponent({image}) {
    return (
      <PhotoProvider>
        <div className="foo">
            <PhotoView key={image} src={image}>
              <img className="w-[60px] h-[60px] rounded-md" src={image} alt="" />
            </PhotoView>
        </div>
      </PhotoProvider>
    );
  }

const NoLocations = () => {
    const {t} = useTranslation()
    return (
        <div className="flex w-full h-full justify-center items-center">
            <h2 className="text-gray-500 font-bold text-md w-full text-center px-8">{`${t('locationDataNotFound')}.`} </h2>
        </div>
    )
}

const DocumentItem = (props) => {
    const {
      mv_value,
      fs_filetype,
      doc_size,
      dom_name,
      doc_filename,
      doc_url,
      mv_id_scan,
      onDocumentHover,
      lat,
      long,
      scanId
    } = props;
    const navigate = useNavigate();
    return (
      <tr
        onMouseOver={() => {
          onDocumentHover(mv_id_scan, { lat, long });
        }}
        className="text-xs hover:bg-slate-100 w-full"
      >
        <td>
          <div
            onClick={() => window.open(`http://${doc_url}`, "_blank")}
            className="font-bold text-slate-500 flex cursor-pointer"
          >
            <span className="break-all">{doc_filename}</span>{" "}
            <span>
              <ArrowSquareIn size={ 16} />
            </span>
          </div>
          <span className="text-slate-400 flex">{dom_name}</span>
        </td>
        <td>
          <span className="text-slate-500 flex">{fs_filetype}</span>
        </td>
        <td>
          <span className="text-slate-500 flex">{filesize(doc_size)}</span>
        </td>   
        {!scanId ? <td>
          <span
            className="text-slate-500 cursor-pointer"
            onClick={() => navigate(`/dashboard/scans/${mv_id_scan}`)}
          >
            <ArrowCircleRight size={16} />
          </span>
        </td> : ''}
      </tr>
    );
  };

const DomainItem = (props) => {

    const { dom_id_scan, dom_name, dom_geo, dom_ip, lat, long, onDomainHover, scanId } = props;

    const navigate = useNavigate()

    return <tr onMouseOver={() => {
        onDomainHover(dom_id_scan, { lat, long });
      }}
      className="text-xs hover:bg-slate-100 w-full">
        <td>
            <div onClick={() => window.open(`http://${dom_name}`, "_blank")} className="font-bold text-slate-500 flex cursor-pointe"><span className="break-all">{dom_name}</span> <span><ArrowSquareIn size={16} /></span></div>
        </td>
        <td>
            <span className="text-slate-500 flex">{dom_geo.country ? dom_geo.country?.names?.en : 'Not found'}</span>
        </td>
        <td>
            <span className="text-slate-500 flex">{dom_geo.city ? dom_geo.city?.names?.en : 'Not found'}</span>
        </td>
        <td>
            <span className="text-slate-500 flex">{dom_ip}</span>
        </td>
        {!scanId ? <td>
            <span className="text-slate-500 cursor-pointer" onClick={() => navigate(`/dashboard/scans/${dom_id_scan}`)}><ArrowCircleRight size={16} /></span>
        </td> : '' }
    </tr>
}

export const LocationsCard = ({ scanId = '' , mapHeight }) => {
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([])
    const [organisations, setOrganisations] = useState([])
    const [organisationsFilter, setOrganisationsFilter] = useState([])
    const [activeDocument, setActiveDocument] = useState({ lat: 0, lng: 0 });
    const [activeDomain, setActiveDomain] = useState({ lat: 0, lng: 0 });

    const [domains, setDomains] = useState([])
    const [mode, setMode] = useState(scanId ? 'domains' : 'documents')
    const [totalItems, setTotalItems] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    const itemsPerPage = 100;

    const [infowindowOpen, setInfowindowOpen] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();

    const navigate = useNavigate()

    const getDocuments = async() => {
        const documents = await getResultsByGroupId('17', itemOffset, scanId)

        const locationsArray = []
    
        Object.keys(documents.payload ?? {}).forEach((document) => {
            const latLong = documents.payload[document].mv_value.split(" ");
            if (latLong.length > 2) return;
            if (!isNaN(Number(latLong[0])) && !isNaN(Number(latLong[1]))) {
              const validLocationItem = {
                ...documents.payload[document],
                lat: Number(latLong[0]),
                long: Number(latLong[1]),
              };
              locationsArray.push(validLocationItem);
            }
          });

        setTotalItems(documents.message[0].doc_total_unique)
        setLoading(false)
        setDocuments(locationsArray)
    }

    const getDomains = async() => {
        try {
            const domains = await getLatestDomains(itemOffset, scanId)
            let domainsArray = []

            Object.keys(domains.payload).forEach((domain) => {
                    const validDomainItem = {
                    ...domains.payload[domain],
                    lat: Number(
                        domains.payload[domain].dom_geo.location.latitude
                    ), 
                    long: Number(
                        domains.payload[domain].dom_geo.location.longitude
                    ), 
                    };
                    domainsArray.push(validDomainItem);
                });

            setTotalItems(domains.message[0].dom_total)
            setDomains(domainsArray);
            setLoading(false);
        } catch (error) {
            console.log('Error retrieving latest domains', error)
        }
    }

    const handleModeChange = async (mode) => {
        setMode(mode)
        setLoading(true)

        if(mode == 'documents') {
            await getDocuments()
            setLoading(false)
        } else {
            await getDomains()
            setLoading(false)
        }
    }

    useEffect(() => {
        if(mode == 'documents') {
            getDocuments()
        } else {
            getDomains()            
        }

    }, [itemOffset, mode])

    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage);
        setItemOffset(newOffset);
    };


    const unsubscribe = store.subscribe(handleChange)

    function select(state) {
        return state
    }
      
    let currentAssetsValue

    function handleChange() {
        let previousAssetsValue = currentAssetsValue
        currentAssetsValue = select(store.getState())
        let orgsArray = []
    
        if (previousAssetsValue !== currentAssetsValue) {
            currentAssetsValue.organisations.forEach((o) => {
                orgsArray.push({ label: o.org_name, value: o.org_id})
            })

            setOrganisations(orgsArray)
        }
    }

    const baseSelectStyle = {     
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: 'rgb(226, 232, 240)',
            borderRadius: '0.6em',
            outline: state.isFocused ? 'none' : 'none'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: 'rgb(148 163 184)',
            outline: 'none'
        })
    }

    const setNewOrganisations = async(input, callback) => {
        const { payload } = await searchOrganisations(input)    
        setOrganisationsFilter([])

        let orgsArray = []

        Object.keys(payload).forEach((o) => {
            orgsArray.push({ label: payload[o].org_name, value: payload[o].org_id})
        })
        setOrganisations(orgsArray) 
        callback(orgsArray)
    }

    const onDocumentHover = (id, position) => {
        setActiveDocument({ lat: position.lat, lng: position.long });
      };

    const onDomainHover = (id, position) => {
        setActiveDomain({ lat: position.lat, lng: position.long });
    };
    const {t} = useTranslation()
    
    return (
        <div className={`flex flex-col h-full w-full`}>
            {loading ? <Loader /> :

            <div class="grid grid-cols-12">

            <div class="col-span-5 max-h-[100vh] overflow-scroll">

            {!loading ? <div className="flex text-slate-600">
                <button onClick={() => handleModeChange('documents')} className="flex items-center text-xs space-x-2  hover:bg-gray-100 rounded-sm p-4 w-full text-slate-400 "><FileDoc size={16} /> <span>{t('documents')}</span></button>
                <button onClick={() => handleModeChange('domains')} className="flex items-center text-xs space-x-2 hover:bg-gray-100 rounded-sm p-4 w-full text-slate-400"><Browsers size={16} /> <span>{t('domains')}</span></button>
            </div> : ''}

            {!scanId ? <div className="pagination pagination-wrapper cursor-not-allowed mb-2">
                <ReactPaginate 
                    breakLabel="..."
                    nextLabel=">"
                    onClick={() => {
                        if(loading) return false
                    }}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    className={`${loading ? 'opacity-20 cursor-not-allowed' : ''}`}            
                />
            </div> : ''}

            {mode == 'documents' && !loading && documents.length ? 
                    <table className="w-full data-explorer" cellPadding={"10"}>
                    <thead className="text-xs text-gray-500 text-left bg-slate-50">
                        <th>{t('document')}</th>
                        <th>{t('type')}</th>
                        <th>{t('size')}</th>
                        {!scanId ? <th>{t('scan')}</th> : ''}
                    </thead>
                    <tbody className={`${scanId ? 'block max-h-[300px] overflow-scroll' : 'block max-h-[100vh] overflow-scroll'}`}>
                    {documents && documents.length
                        ? documents.map((l) => (
                            <DocumentItem scanId={scanId} onDocumentHover={onDocumentHover} {...l} />
                            ))
                        : ""}
                    </tbody>
            </table>
                : ''} 

            {mode == 'domains' && !loading && domains.length ? 
                    <table className="w-full data-explorer" cellPadding={"10"}>
                    <thead className="text-xs text-gray-500 text-left bg-slate-50">
                        <th>{t('domain')}</th>
                        <th>{t('country')}</th>
                        <th>{t('city')}</th>
                        <th>{t('ipAddress')}</th>
                        {!scanId ? <th>{t('scan')}</th> : ''}
                    </thead>
                    <tbody className={`${scanId ? 'block max-h-[300px] overflow-scroll' : 'block max-h-[100vh] overflow-scroll'}`}>
                    {domains && domains.length
                        ? domains.map((l) => (
                            <DomainItem scanId={scanId} onDomainHover={onDomainHover} {...l} />
                            ))
                        : ""}
                    </tbody>
            </table>
                : ''}

            </div>

            <div class="flex col-span-7">
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                {mode == 'documents' && !loading && !documents.length ? <NoLocations /> : (!loading && documents.length) && mode == 'documents' ?
                <div className={`w-full overflow-hidden h-[${scanId ? '50vh' : '100vh'}`}>
                        <Map
                            style={mapHeight ? {width: "100%", height: mapHeight} : {
                                width: "100%",
                                height: "120%"
                            }}
                            mapId={'d2a14be47831f217'}
                            zoom={6} 
                            center={
                                activeDocument.lat !== 0 && activeDocument.lng !== 0
                                  ? { lat: activeDocument.lat, lng: activeDocument.lng }
                                  : { lat: documents[0].lat, lng: documents[0].long }
                              }
                            gestureHandling={'greedy'}
                            disableDefaultUI={true}
                        >
                        {documents && documents.length ? documents.map((l) => 
                            <AdvancedMarker
                                ref={markerRef}
                                onClick={() => setInfowindowOpen(l.mv_id_document)}
                                position={{lat: l.lat, lng: l.long}}
                            >
                            <Pin
                                background={'#0a161f'}
                                borderColor={'#0a161f'}
                                glyphColor={'#ffffff'}
                                >
                            </Pin>
                            {infowindowOpen == l.mv_id_document ? (
                                <InfoWindow
                                className="p-2 text-xs"
                                position={{lat: l.lat, lng: l.long}}
                                maxWidth={200}
                                onCloseClick={() => setInfowindowOpen(null)}>
                                    <div className="text-xs p-1 flex space-x-2">
                                        <PhotoComponent image={`http://${l.doc_url}`} />
                                        <div>
                                            <span className="text-slate-500 flex font-bold">{l.doc_filename}</span>
                                            <span className="text-slate-400 flex">Filetype: {l.fs_filetype}</span>                                            
                                        </div>
                                        {/** <span className="text-slate-400 space-x-1 flex cursor-pointer mt-1" onClick={() => navigate(`/dashboard/scans/${l.mv_id_scan}`)}><span>View scan</span> <ArrowCircleRight size={16} /></span> **/}
                                    </div>
                                </InfoWindow>
                            ) : ''}
                            </AdvancedMarker>
                            ) : ''}
                        </Map>
                </div> : '' }

                {mode == 'domains' && !loading && !domains.length ? <NoLocations /> : !loading && mode == 'domains' ?
                <div className={`w-full overflow-hidden h-[${scanId ? '50vh' : '100vh'}`}>
                    <Map
                            style={mapHeight ? {width: "100%", height: mapHeight} : {
                                width: "100%",
                                height: "120%"
                            }}
                            mapId={'d2a14be47831f217'}
                            zoom={4}
                            center={
                                activeDomain.lat !== 0 && activeDomain.lng !== 0
                                ? { lat: activeDomain.lat, lng: activeDomain.lng }
                                : { lat: domains[0].lat ?? 0, lng: domains[0].lng ?? 0}
                             }                           
                            gestureHandling={'greedy'}
                            // eslint-disable-next-line react/jsx-boolean-value
                            disableDefaultUI={true}
                        >
                        {domains && domains.length ? domains.map((d) => 
                            <AdvancedMarker
                            ref={markerRef}
                            onClick={() => setInfowindowOpen(d.dom_name)}
                            position={{lat: Number(d.lat), lng: Number(d.long)}}
                        >
                        <Pin
                                background={'#0a161f'}
                                borderColor={'#0a161f'}
                                glyphColor={'#ffffff'}
                            >
                        </Pin>
                        {infowindowOpen == d.dom_name ? (
                            <InfoWindow
                            className="p-2 text-xs"
                            position={{lat: Number(d.lat), lng: Number(d.long)}}
                            maxWidth={200}
                            onCloseClick={() => setInfowindowOpen(null)}>
                                <div cla    ssName="text-xs p-1">
                                <span onClick={() => window.open(`http://${d.dom_name}`, "_blank")} className="text-slate-500 flex font-bold cursor-pointer"><span>{d.dom_name}</span> <ArrowSquareIn size={16} /></span>
                                <span className="text-slate-400 flex">IP: {d.dom_ip}</span>
                                <span className="text-slate-400 space-x-1 flex cursor-pointer mt-1" onClick={() => navigate(`/dashboard/scans/${d.dom_id_scan}`)}><span>View scan</span> <ArrowCircleRight size={16} /></span>
                                </div>
                            </InfoWindow>
                        ) : ''}
                        </AdvancedMarker>
                            ) : ''}
                        </Map>
                </div> : '' }</APIProvider>
            </div>
            </div> }          
        </div>
    )

}

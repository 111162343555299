import config from "../../config/index"
import store from "../../store"
import { update } from "../../store/organisations"
import { getAllAssets } from "../assets/assets"

const { API_ENDPOINT } = config

// const payloadWithId = (payload) => ({ id_app: APP_ID, ...payload });

const getHeaders = () => {
  const token = localStorage.getItem("s:Token")
  const headers = {
    Authorization: token === null ? "" : `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  }
  return headers
}

export const getAllOrganisations = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}/organizations/`, {
      method: "GET",
      headers: getHeaders(),
    })

    if (!response.ok) {
      throw new Error(
        `Error while retrieving organisations: ${response.statusText}`,
      )
    }
    const data = await response.json()
    const { payload } = data
    const organisationsArray = []

    Object.keys(payload).forEach((key) => {
      organisationsArray.push(payload[key])
    })

    store.dispatch(update(organisationsArray))

    return organisationsArray
  } catch (error) {
    console.log("Error while retrieving organisations", error)
    return error
  }
}

export const getOrganisation = async (id) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/organizations/?org_id=${id}&include_assets=True`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )

    if (!response.ok) {
      throw new Error(
        `Error while retrieving organisations: ${response.statusText}`,
      )
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log("Error while retrieving organisations", error)
    return error
  }
}

export const searchOrganisations = async (name) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/organizations/search?search_term=${name}`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )

    if (!response.ok) {
      throw new Error(
        `Error while retrieving organisations: ${response.statusText}`,
      )
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log("Error while retrieving organisations", error)
    return error
  }
}

export const searchOrganisationsByTerm = async (name) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/organizations/search?search_term=${name}`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )

    if (!response.ok) {
      throw new Error(
        `Error while retrieving organisations: ${response.statusText}`,
      )
    }

    const data = await response.json()
    const { payload } = data
    const organisationsArray = []

    Object.keys(payload).forEach((key) => {
      organisationsArray.push(payload[key])
    })
    return organisationsArray
  } catch (error) {
    console.log("Error while retrieving organisations", error)
    return error
  }
}

export const deactivateOrganisation = async (id) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/organizations/status/deactivate/${id}`,
      {
        method: "PUT",
        headers: getHeaders(),
      },
    )

    await getAllOrganisations()
    return response
  } catch (error) {
    console.log("Error deactivating organisation", error)
    return false
  }
}

export const activateOrganisation = async (id) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/organizations/status/activate/${id}`,
      {
        method: "PUT",
        headers: getHeaders(),
      },
    )
    await getAllOrganisations()
    return response
  } catch (error) {
    console.log("Error deactivating organisation", error)
    return false
  }
}

export const createOrganisation = async (payload) => {
  const { name, reference, caseStudy, tld } = payload
  try {
    const response = await fetch(`${API_ENDPOINT}/organizations/create`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        org_group: caseStudy,
        org_name: name,
        org_ticket: reference,
        tld_user: tld,
      }),
    })

    if (!response.ok) {
      throw new Error(
        `Error while retrieving organisations: ${response.statusText}`,
      )
    }
    const data = await response.json()
    await getAllAssets()
    await getAllOrganisations()
    return data
  } catch (error) {
    console.log("Error while retrieving organisations", error)
    return error
  }
}

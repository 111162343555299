/* eslint-disable react/prop-types */
import React, { useState } from "react"
import {
  Binoculars,
  Buildings,
  ChartLine,
  Eraser,
  FloppyDiskBack,
  Gauge,
  Gear,
  MapTrifold,
  Radioactive,
  Scan,
  SignOut,
  Wallet,
  WarningOctagon,
} from "@phosphor-icons/react"
import { NavLink, Outlet, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import iconImage from "../../images/icon-only-dark-bg.png" // ????? duplicate VAR conflicts with prop ????
import { logout } from "../../api/auth/auth"
import { update } from "../../store/user"
import { getAllOrganisations } from "../../api/organisations/organisations"
import { getAllAssets } from "../../api/assets/assets"
import { getAllScans } from "../../api/scans/scans"
import { getGroups, getThreats } from "../../api/settings/settings"

function SidebarNavItem({ text, onClick, icon, disabled, route, active, end }) {
  return (
    <NavLink
      disabled={disabled}
      active={active}
      end={end}
      to={route}
      className={({ isActive }) =>
        isActive ? "text-slate-500" : "text-gray-400"
      }
    >
      <button
        type="button"
        onClick={onClick}
        className={`flex items-center py-2 text-xs  hover:bg-gray-100 rounded-sm p-2 w-full ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
      >
        {icon}
        <span className="ml-2">{text}</span>
      </button>
    </NavLink>
  )
}

function SidebarHeader({ text }) {
  return (
    <span className="text-xs text-gray-300 p-2 flex pt-4 uppercase">
      {text}
    </span>
  )
}

function Dashboard() {
  const [loading, setLoading] = useState(true)
  const { t, i18n } = useTranslation()
  const lngs = {
    en: { nativeName: "EN" },
    es: { nativeName: "ES" },
  }
  const getInitialData = async () => {
    await getAllOrganisations()
    await getAllAssets()
    await getAllScans()
    await getThreats()
    await getGroups()
  }

  getInitialData()

  setTimeout(() => {
    setLoading(false)
  }, 2000)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleLogout = async () => {
    const response = await logout()
    if (response) {
      dispatch(update({}))
      navigate("/")
    }
  }
  return (
    <div className="bg-gray-100 w-[100vw] h-[100vh] relative flex">
      {loading ? (
        <div className="loader m-auto">
          <span className="scale-[200%]" />
        </div>
      ) : (
        ""
      )}
      {!loading ? (
        <>
          <div className="p-4 h-full w-56 bg-white shadow-md border-r border-gray-200">
            <section className="flex flex-row">
              <img className="w-12 p-2 pb-4" src={iconImage} alt="" />
              <div className="ml-8">
                {Object.keys(lngs).map((lng) => (
                  <button
                    key={lng}
                    className={`${i18n.resolvedLanguage === lng ? "rounded-md border bg-blend-overlay bg-gray-100 p-2 font-bold text-xs text-gray-400" : "rounded-md p-2 font-bold text-xs text-gray-400"}`}
                    type="submit"
                    onClick={() => i18n.changeLanguage(lng)}
                  >
                    {lngs[lng].nativeName}
                  </button>
                ))}
              </div>
            </section>
            <nav>
              <SidebarNavItem
                end
                route="/dashboard"
                active
                icon={<ChartLine size={20} />}
                text={t("dashboard")}
              />
              <SidebarHeader text={t("tools")} />
              <SidebarNavItem
                end
                route="/dashboard/scan-and-clean"
                active
                icon={<Eraser size={20} />}
                text={t("scanAndClean")}
              />
              <SidebarHeader text={t("analytics")} />
              <SidebarNavItem
                route="/dashboard/data-explorer"
                icon={<Binoculars size={20} />}
                text={t("dataExplorer")}
              />
              <SidebarNavItem
                end
                route="/dashboard/locations"
                icon={<MapTrifold size={20} />}
                text={t("locations")}
              />
              <SidebarNavItem
                end
                route="/dashboard/scans"
                icon={<Scan size={20} />}
                text={t("scans")}
              />
              <SidebarNavItem
                end
                route="/dashboard/threats"
                icon={<Radioactive size={20} />}
                text={t("threats")}
              />
              <SidebarHeader text={t("observability")} />
              <SidebarNavItem
                end
                route="/dashboard/monitor"
                onClick={(e) => e.preventDefault()}
                disabled
                icon={<Gauge size={20} />}
                text={t("monitor")}
              />
              <SidebarNavItem
                end
                route="/dashboard/alerts"
                onClick={(e) => e.preventDefault()}
                disabled
                icon={<WarningOctagon size={20} />}
                text={t("alerts")}
              />
              <SidebarHeader text={t("administration")} />
              <SidebarNavItem
                end
                route="/dashboard/plans"
                icon={<Wallet size={20} />}
                text="Plans & Payments"
              />
              <SidebarNavItem
                end
                route="/dashboard/organisations"
                icon={<Buildings size={20} />}
                text={t("organisations")}
              />
              <SidebarNavItem
                end
                route="/dashboard/assets"
                icon={<FloppyDiskBack size={20} />}
                text={t("assets")}
              />
              <SidebarNavItem
                end
                route="/dashboard/settings"
                icon={<Gear size={20} />}
                text={t("settings")}
              />
              <SidebarNavItem
                end
                route="/dashboard/logout"
                active={false}
                onClick={() => handleLogout()}
                icon={<SignOut size={20} />}
                text={t("logout")}
              />
            </nav>
          </div>
          <div className="w-full h-full overflow-y-scroll">
            <Outlet />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  )
}

export default Dashboard

import { createSlice } from "@reduxjs/toolkit"

export const threatsSlice = createSlice({
  name: "threats",
  initialState: { threats: [] },
  reducers: {
    update: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state = [...action.payload]
      return state
    },
  },
})

export const { update } = threatsSlice.actions

export default threatsSlice.reducer

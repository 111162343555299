/* eslint-disable camelcase */
import config from "../../config/index"

const { API_ENDPOINT } = config

// const payloadWithId = (payload) => ({ id_app: APP_ID, ...payload })

const getHeaders = () => {
  const token = localStorage.getItem("s:Token")
  const headers = {
    Authorization: token === null ? "" : `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  }
  return headers
}

export const getLatestMetavalues = async ({
  limit,
  offset,
  include_filetype_id,
  exclude_filetype_id,
  include_group_id,
  org_id,
  scan_id = "",
}) => {
  try {
    let url = `${API_ENDPOINT}/results/metavalues?offset=${offset}&limit=${limit}&include_group_id=${include_group_id}&org_id=${org_id}&scan_id=${scan_id}`

    if (include_filetype_id) {
      url += `&include_filetype_id=${include_filetype_id}`
    }
    if (exclude_filetype_id) {
      url += `&exclude_filetype_id=${exclude_filetype_id}`
    }

    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    })

    if (!response.ok) {
      throw new Error(`Error while retrieving results: ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.log("Error while retrieving results", error)
    return error
  }
}

export const getLatestDomains = async (offset = "", scanId = "") => {
  console.log("Getting latest domains", offset, scanId)
  try {
    const response = await fetch(
      `${API_ENDPOINT}/results/domains?offset=${offset}&scan_id=${scanId}`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )

    if (!response.ok) {
      throw new Error(`Error while retrieving domains: ${response.statusText}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log("Error while retrieving domains", error)
    return error
  }
}

export const getLatestDocuments = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}/results/documents`, {
      method: "GET",
      headers: getHeaders(),
    })
    if (!response.ok) {
      throw new Error(
        `Error while retrieving documents: ${response.statusText}`,
      )
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log("Error while retrieving documents", error)
    return error
  }
}

export const getResultsByScanId = async (scanId = "") => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/results/metavalues?scan_id=${scanId}`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )

    if (!response.ok) {
      throw new Error(`Error while retrieving results: ${response.statusText}`)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.log("Error while retrieving results", error)
    return error
  }
}

export const getResultsByGroupId = async (groupId, offset, scanId = "") => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/results/metavalues?include_group_id=${groupId}&offset=${offset}${scanId ? `&scan_id=${scanId}` : ""}`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )

    if (!response.ok) {
      throw new Error(
        `Error while retrieving results by group: ${response.statusText}`,
      )
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log("Error while retrieving results by group", error)
    return error
  }
}

import { createSlice } from "@reduxjs/toolkit"

export const scansSlice = createSlice({
  name: "scans",
  initialState: { scans: [] },
  reducers: {
    update: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state = [...action.payload]
      return state
    },
  },
})

export const { update } = scansSlice.actions

export default scansSlice.reducer

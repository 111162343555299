import config from "../../config/index"
import store from "../../store/index"
import { update } from "../../store/scans"

const { API_ENDPOINT } = config

// const payloadWithId = (payload) => ({ id_app: APP_ID, ...payload })

const getHeaders = () => {
  const token = localStorage.getItem("s:Token")
  console.log("Getting token before request", token)
  const headers = {
    Authorization: token === null ? "" : `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  }
  return headers
}

export const getAllScans = async (id) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/scans/get${id ? `?scan_id=${id}` : ""}`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )

    if (!response.ok) {
      throw new Error(`Error while retrieving scans: ${response.statusText}`)
    }
    const data = await response.json()
    const { payload } = data
    const scanArray = []
    Object.keys(payload).forEach((key) => {
      scanArray.push(payload[key])
    })
    store.dispatch(update(scanArray))
    return scanArray
  } catch (error) {
    console.log("Error while retrieving scans", error)
    return error
  }
}

export const createScan = async (assetId) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/scans/create/${assetId}`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        assetId,
      }),
    })

    if (!response.ok) {
      throw new Error(`Error while creating a scan: ${response.statusText}`)
    }
    const data = await response.json()
    await getAllScans()

    return data
  } catch (error) {
    console.log("Error while creating scan", error)
    return error
  }
}

export const getScanById = async (id) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/scans/get${id ? `?scan_id=${id}` : ""}`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )
    if (!response.ok) {
      throw new Error(`Error while retrieving scans: ${response.statusText}`)
    }
    const data = await response.json()
    const { payload } = data
    console.log("Got scan by ID", payload[id])
    return payload
  } catch (error) {
    console.log("Error while retrieving scans", error)
    return error
  }
}

export const searchScans = async (term) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/scans/search${term ? `?search_term=${term}` : ""}`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )
    if (!response.ok) {
      throw new Error(`Error while retrieving scans: ${response.statusText}`)
    }
    const data = await response.json()
    const { payload } = data
    const scanArray = []
    Object.keys(payload).forEach((key) => {
      scanArray.push(payload[key])
    })
    return scanArray
  } catch (error) {
    console.log("Error while retrieving scans", error)
    return error
  }
}

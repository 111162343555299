import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../components/common/Button"
import Input from "../../components/common/Input"
import Loader from "../../components/common/Loader"
import { register } from "../../api/auth/auth"
import Header from "../../components/common/Header"
import bg from "../../images/bg-1.png"

function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState("")
  const [registered, setRegistered] = useState(false)
  const [terms, setTerms] = useState(false)
  const { t } = useTranslation()
  const handleRegister = async () => {
    if (password.trim().length > 7) {
      setError("")
      if (password !== confirmPassword) {
        setError("Passwords do not match")
        return
      }
      setLoading(true)
      const response = await register({ email, password })
      setLoading(false)
      if (!response) {
        setError("Error when attemping sign up, try again.")
        return
      }
      if (response.status === 200) {
        setRegistered(true)
      }
    } else {
      setError(t("passwordLengthShouldBe"))
    }
  }

  return (
    <div className="h-full w-full flex flex-col">
      <div className="h-full grid grid-cols-12">
        <div className="col-span-4 h-full w-full">
          <Header />
        </div>
        <div
          style={{ backgroundImage: `url(${bg})` }}
          className="col-span-8 bg-cover bla h-full w-full"
        >
          <div className="space-y-8 mt-12 flex-grow">
            <div className="p-12 space-y-6 w-[540px] m-auto">
              <h1 className="text-slate-500 text-xl font-semibold">
                {t("register")}
              </h1>
              <p className="text-slate-400 text-sm">
                {t("enterDetailsBelowToCreateAnAccount")}.
              </p>
              {!loading && !registered ? (
                <>
                  <Input
                    size="large"
                    onChange={(v) => setEmail(v)}
                    label="Email Address"
                    type="email"
                    placeholder={t("emailAddress")}
                  />
                  <Input
                    size="large"
                    onChange={(v) => setPassword(v)}
                    label="Password"
                    type="password"
                    placeholder={t("password")}
                  />
                  <Input
                    size="large"
                    onChange={(v) => setConfirmPassword(v)}
                    label="Password"
                    type="password"
                    placeholder={t("confirmPassword")}
                  />
                  <div className="text-[10px] p-2 text-gray-500 flex align-middle mb-4">
                    <input
                      onChange={() => setTerms(!terms)}
                      className="mr-4"
                      type="checkbox"
                    />
                    {t("privacy")}
                  </div>
                  <Button
                    disabled={!email || !password || !confirmPassword || !terms}
                    text={t("register")}
                    onClick={() => handleRegister()}
                  />
                </>
              ) : (
                ""
              )}
              {registered ? (
                <div className="bg-green-100 text-green-700 p-4 mt-2 rounded-md">
                  {t("userSuccessfullyRegistered")},
                  <a className="underline font-bold" href="/">
                    {t("loginHere")}.
                  </a>
                </div>
              ) : (
                ""
              )}
              {error ? (
                <div className="bg-red-100 text-red-500 text-sm p-4 rounded-md">
                  {error}
                </div>
              ) : (
                ""
              )}
              {loading ? <Loader /> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword

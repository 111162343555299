import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./components/common/common.css"
import "react-photo-view/dist/react-photo-view.css"

import "./i18n/i18n"

import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Provider } from "react-redux"
import Login from "./screens/auth/Login"
import ForgotPassword from "./screens/auth/ForgotPassword"
import Register from "./screens/auth/Register"
import Explore from "./screens/explore/Explore"
import Dashboard from "./screens/auth/Dashboard"
import store from "./store"
import Scan from "./screens/dashboard/Scan"
import Organisation from "./screens/dashboard/Organisation"
import DashboardIndex from "./screens/dashboard/DashboardIndex"
import Organisations from "./screens/dashboard/Organisations"
import Scans from "./screens/dashboard/Scans"
import Threats from "./screens/dashboard/Threats"
import Locations from "./screens/dashboard/Locations"
import Assets from "./screens/dashboard/Assets"
import Settings from "./screens/dashboard/Settings"
import Asset from "./screens/dashboard/Asset"
import DataExplorer from "./screens/dashboard/DataExplorer"
import ScanAndClean from "./screens/dashboard/ScanAndClean"
import Plans from "./screens/dashboard/Plans"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    children: [
      {
        index: true,
        element: <DashboardIndex />,
      },
      {
        path: "scan-and-clean",
        element: <ScanAndClean />,
      },
      {
        path: "organisations",
        element: <Organisations />,
      },
      {
        path: "organisations/:id",
        element: <Organisation />,
      },
      {
        path: "scans",
        element: <Scans />,
      },
      {
        path: "scans/:id",
        element: <Scan />,
      },
      {
        path: "threats",
        element: <Threats />,
      },
      {
        path: "locations",
        element: <Locations />,
      },
      {
        path: "assets",
        element: <Assets />,
      },
      {
        path: "assets/:id",
        element: <Asset />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "data-explorer",
        element: <DataExplorer />,
      },
      {
        path: "plans",
        element: <Plans />,
      },
    ],
  },
  {
    path: "explore",
    element: <Explore />,
  },
])

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
)

import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  ArrowUDownLeft,
  Buildings,
  FloppyDiskBack,
  MapTrifold,
  Radioactive,
  Scan,
} from "@phosphor-icons/react"
import { useSelector } from "react-redux"
import Toggle from "react-toggle"
import { useTranslation } from "react-i18next"
import Button from "../../components/common/Button"
import {
  activateOrganisation,
  deactivateOrganisation,
} from "../../api/organisations/organisations"

const mockScore = Math.floor(Math.random() * 100) + 1

function Organisation() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id } = useParams()
  // eslint-disable-next-line eqeqeq, prettier/prettier
  const organisation = useSelector((state) => state.organisations.filter((o) => o.org_id == id)[0])
  const assets = useSelector((state) => state.assets)
  const [isEnabled, setIsEnabled] = useState(organisation.org_is_enabled)
  const toggleOrganisation = async () => {
    if (isEnabled) {
      // eslint-disable-next-line no-unused-vars
      const response = await deactivateOrganisation(organisation.org_id)
      setIsEnabled(false)
    } else {
      // eslint-disable-next-line no-unused-vars
      const response = await activateOrganisation(organisation.org_id)
      setIsEnabled(true)
    }
  }
  const goToAssets = (assetId) => {
    navigate(`/dashboard/assets/${assetId}`)
  }

  const assetsLength = Object.keys(
    organisation.assets?.tld ? organisation.assets?.tld : {},
  ).length
  const scanCount = assetsLength
  const totalScore = assetsLength >= 1 ? mockScore + 1 : 0
  const filteredAssets = assets.filter((asset) => asset.org.org_id === id)
  return (
    <div className="flex flex-col flex-grow w-full h-full p-4 space-y-2">
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="flex uppercase text-xs text-slate-400"
        >
          <ArrowUDownLeft className="p-1" size={18} />
          {t("back")}
        </button>
      </div>
      <div>
        <h1 className="text-lg text-slate-600 flex items-center space-x-2">
          <Buildings /> <span>{organisation.org_name}</span>
        </h1>
      </div>
      <hr />
      <div className="grid grid-cols-12 space-x-4">
        <div className="col-span-9 space-y-4">
          <div className="flex text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-x-4 items-start justify-start">
            <div className="h-[120px] w-[95px]">
              <h2 className="text-center text-slate-600">
                {t("overallScore")}
              </h2>
              <div className={`circle percentage-${totalScore} scale-150 p-4`}>
                <span>{totalScore}</span>
                <div className="percentage-bar" />
              </div>
            </div>
            <div className="flex flex-col h-full text-center">
              <h2 className="text-center mb-10 text-slate-600">
                {t("numberOfAssets")}
              </h2>
              <span className="text-[60px]">{assetsLength}</span>
            </div>
            <div className="flex flex-col h-full text-center">
              <h2 className="text-center mb-10 text-slate-600">
                {t("totalThreats")}
              </h2>
              <span className="text-[60px]">-</span>
            </div>
            <div className="flex flex-col h-full text-center">
              <h2 className="text-center mb-10 text-slate-600">
                {t("totalScans")}
              </h2>
              <span className="text-[60px]">{scanCount}</span>
            </div>
            <div className="flex flex-col h-full text-center">
              <h2 className="text-center mb-10 text-slate-600">
                {t("activeScans")}
              </h2>
              <span className="text-[60px]">0</span>
            </div>
            <div className="flex flex-col h-full text-center">
              <h2 className="text-center mb-10 text-slate-600">
                {t("documentsFound")}
              </h2>
              <span className="text-[60px]">-</span>
            </div>
          </div>
          <div className="flex flex-col text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-y-2 items-start justify-start">
            <h1 className="text-lg text-slate-600 flex items-center space-x-2">
              <FloppyDiskBack />
              <span>{t("assets")}</span>
            </h1>
            {assetsLength >= 1 ? (
              filteredAssets.map((asset) => (
                <div className="flex flex-col">
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") goToAssets(asset.tld_id)
                    }}
                    onClick={() => goToAssets(asset.tld_id)}
                    className="font-bold text-slate-500 cursor-pointer"
                  >
                    {asset.tld_user}
                  </span>
                  <span>Type: Website</span>
                </div>
              ))
            ) : (
              <span>{t("noAssetsFound")}</span>
            )}
          </div>
          <div className="flex flex-col text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-y-2 items-start justify-start">
            <h1 className="text-lg text-slate-600 flex items-center space-x-2">
              <Radioactive />
              <span>{t("threats")}</span>
            </h1>
            <span>{t("noThreatDataFound")}</span>
          </div>
          <div className="flex flex-col text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-y-2 items-start justify-start">
            <h1 className="text-lg text-slate-600 flex items-center space-x-2">
              <MapTrifold />
              <span>{t("locations")}</span>
            </h1>
            <span>{t("noLocationDataFound")}</span>
          </div>
          <div className="flex flex-col text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-y-2 items-start justify-start">
            <h1 className="text-lg text-slate-600 flex items-center space-x-2">
              <Scan /> <span>{t("scans")}</span>
            </h1>
            <span>{t("noScanDataFound")}</span>
          </div>
        </div>
        <div className="col-span-3 space-y-4">
          <div className="flex flex-col space-y-4">
            <span className="text-slate-600 text-xs">
              {t("enableDisableOrganisation")}
            </span>
            <Toggle
              checked={isEnabled}
              name="organisationEnabled"
              value
              onChange={toggleOrganisation}
            />
          </div>
          <hr />
          <div className="flex flex-col space-y-4">
            <span className="text-slate-600 text-xs">
              {t("enableDisableMonitoring")}
            </span>
            <Toggle
              disabled
              checked={organisation.org_is_monitored}
              name="organisationEnabled"
              value
              onChange={toggleOrganisation}
            />
          </div>
          <Button disabled primary={false} text={t("deleteOrganisation")} />
        </div>
      </div>
    </div>
  )
}

export default Organisation

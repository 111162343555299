/* eslint-disable react/prop-types */
import React from "react"

function Modal({ isOpen, onClose, children, title, icon }) {
  const modalOverlayClasses = isOpen
    ? "fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-10"
    : "hidden"

  const modalClasses = isOpen
    ? "bg-white rounded shadow-md min-w-[360px]"
    : "hidden"

  return (
    <div
      tabIndex={0}
      role="button"
      className={modalOverlayClasses}
      onClick={onClose}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClose()
        }
      }}
    >
      <div
        role="button"
        tabIndex={0}
        className={modalClasses}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation()
          }
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {title ? (
          <div className="flex items-center text-xs text-gray-500 border-b border-gray-100">
            {icon ? (
              <span className="bg-cover p-2 rounded-tl-md">{icon}</span>
            ) : (
              ""
            )}
            {title ? (
              <span className="flex-grow w-full font-bold">{title}</span>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {children}
      </div>
    </div>
  )
}

export default Modal

/* eslint-disable react/prop-types */
import React from "react"

export default function Button(props) {
  const { text, onClick, disabled = false, icon, primary = true } = props

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`p-2 space-y-2 flex justify-center w-full shadow-md rounded-md h-auto ${primary ? "primary py-4" : "secondary"} text-sm font-bold cursor-pointer focus:outline-none ${disabled ? "opacity-60 cursor-not-allowed" : ""}`}
    >
      {icon || ""} {text}
    </button>
  )
}

/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-globals */
/* eslint-disable prettier/prettier */
import omitBy from "lodash/omitBy"
import isNil from "lodash/isEmpty"

export const cleanParams = (x) => new URLSearchParams(omitBy(x, isNil))

export const mapMetagroupNames = (jsonArray) => {
  const metagroupNameMapping = {
        "tools_hw" : "Hardware Tools",
        "tools_sw": "Software Tools",
        "ip_registered": "IP Registered",
        "ip_trademark": "IP Trademark",
        "ip_copyright": "IP Copyright",
        "context_email_info": "Email Information",
        "contact_fax": "Contact Fax",
        "contact_phone": "Contact Phone",
        "contact_email_addr": "Contact Email Address",
        "geo_gps": "Geographical GPS",
        "name_personal": "Personal Name",
        "name_username": "Username Name",
        "dates_created": "Created Dates",
        "dates_updated_modified": "Updated/Modified Dates",
        "dates_printed": "Printed Dates",
        "tools_fw": "Firewall Tools",
        "dates_published": "Published Dates"
  }

  return jsonArray.map((item) => ({
    pc_id_metagroup: item.pc_id_metagroup,
    pc_metabucket_name: item.pc_metabucket_name,
    pc_metagroup_name:
      metagroupNameMapping[item.pc_metagroup_name] || item.pc_metagroup_name,
    pc_metagroup_sm: item.pc_metagroup_sm,
  }))
}

export const isLatitude = num => isFinite(num) && Math.abs(num) <= 90
export const isLongitude = num => isFinite(num) && Math.abs(num) <= 180
export const isFloat = num => num === +num && num !== (num|0)
export const stripQuotes = str => str.replace(/['"]+/g, '')
export const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value)

import config from "../../config/index"
import { update } from "../../store/assets"
import store from "../../store/index"

const { API_ENDPOINT } = config

// const payloadWithId = (payload) => ({ id_app: APP_ID, ...payload })

const getHeaders = () => {
  const token = localStorage.getItem("s:Token")

  console.log("Getting token before request", token)

  const headers = {
    Authorization: token === null ? "" : `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  }

  return headers
}

export const getAllAssets = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}/assets/`, {
      method: "GET",
      headers: getHeaders(),
    })

    if (!response.ok) {
      throw new Error(`Error while retrieving Assets: ${response.statusText}`)
    }

    const data = await response.json()

    const { payload } = data

    const assetArray = []

    Object.keys(payload).forEach((key) => {
      assetArray.push(payload[key])
    })

    store.dispatch(update(assetArray))
    return data
  } catch (error) {
    console.log("Error while retrieving Assets", error)
    return error
  }
}

export const searchAssets = async (term) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/assets/search?search_term=${term}`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )

    if (!response.ok) {
      throw new Error(`Error while retrieving Assets: ${response.statusText}`)
    }

    const data = await response.json()

    const { payload } = data

    const assetArray = []

    Object.keys(payload).forEach((key) => {
      assetArray.push(payload[key])
    })

    return assetArray
  } catch (error) {
    console.log("Error while retrieving Assets", error)
    return error
  }
}

export const deactivateAsset = async (id) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/assets/status/deactivate/${id}`,
      {
        method: "PUT",
        headers: getHeaders(),
      },
    )
    return response
  } catch (error) {
    console.log("Error deactivating asset", error)
    return false
  }
}

export const activateAsset = async (id) => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/assets/status/activate/${id}`,
      {
        method: "PUT",
        headers: getHeaders(),
      },
    )
    return response
  } catch (error) {
    console.log("Error deactivating asset", error)
    return false
  }
}

export const createAsset = async (payload) => {
  const { domain, selectedOrganisation } = payload
  try {
    const response = await fetch(`${API_ENDPOINT}/assets/create`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        org_id: selectedOrganisation,
        tld_user: domain,
      }),
    })

    if (!response.ok) {
      throw new Error(`Error while retrieving Assets: ${response.statusText}`)
    }

    await getAllAssets()

    const data = await response.json()
    return data
  } catch (error) {
    console.log("Error while retrieving Assets", error)
    return error
  }
}

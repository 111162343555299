import { useDrag, useDrop } from "react-dnd"

export function useDragAndDrop(ref, payload) {
  const [{ isDragging }, drag] = useDrag({
    type: "GRID_ITEM",
    item: { ...payload },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [, drop] = useDrop({
    accept: "GRID_ITEM",
    hover: payload.hover,
  })

  drag(drop(ref))

  return {
    isDragging,
  }
}

export default { useDragAndDrop }

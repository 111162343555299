/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from "react"
import styled from "styled-components"

export default function Grid(props) {
  const gridRef = useRef(null)
  const { children } = props

  // This function adjust each item in the grid accordlying
  // with their height.
  // Each item has to have an inner container, used to calculate
  // its overflow. Check GridItem component for an example.
  const adjustGridItemsHeight = (grid) => {
    const items = grid.children
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      // eslint-disable-next-line radix
      const rowHeight = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-auto-rows"),
      )
      // eslint-disable-next-line radix
      const rowGap = parseInt(
        window.getComputedStyle(grid).getPropertyValue("grid-row-gap"),
      )
      const rowSpan = Math.ceil(
        (item.firstChild.getBoundingClientRect().height + rowGap) /
          (rowHeight + rowGap),
      )
      item.style.gridRowEnd = `span ${rowSpan}`
    }
  }

  const GridWrapper = !children.fullScreen
    ? styled.div`
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
        grid-auto-rows: auto;
        width: 100%;
      `
    : styled.div`
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        grid-auto-rows: 100%;
        width: 100%;
      `

  // eslint-disable-next-line no-unused-vars
  const FullScreenGridWrapper = useEffect(() => {
    const grid = gridRef.current
    adjustGridItemsHeight(grid)
  })

  return <GridWrapper ref={gridRef}>{children}</GridWrapper>
}

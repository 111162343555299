import React, { useState } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import { subscriptionLevels } from "../../consts/subscriptions"
import { paymentLinks } from "../../api/payments/payments"
import { stripQuotes } from "../../utils"

const returnPlanSummary = (plan) => {
  switch (plan) {
    case subscriptionLevels.SCAN_ONLY:
      return (
        <div className="bg-slate-100 p-4 text-slate-500 mt-4 text-xs">
          <span className="font-bold">Scan only</span>
          <p>Your plan allows you to scan one website per day.</p>
        </div>
      )
    case subscriptionLevels.SCAN_PRO:
      return (
        <div className="bg-slate-100 p-4 text-slate-500 mt-4 text-xs">
          <span className="font-bold">Scan pro</span>
          <p>
            Scan multiple websites per day and see advanced insights with no
            access to cleaning.
          </p>
        </div>
      )
    case subscriptionLevels.SCAN_PRO_CLEAN:
      return (
        <div className="bg-slate-100 p-4 text-slate-500 mt-4 text-xs">
          <span className="font-bold">Scan Pro & Clean</span>
          <p>
            Scan multiple websites per day, see advanced insights and clean all
            your documents.
          </p>
        </div>
      )
    default:
      return (
        <p className="text-slate-400 text-sm">
          No subscription found, subscribe below to access advanced features.
        </p>
      )
  }
}

export default function PlansCard() {
  const [user, setUser] = useState(useSelector((state) => state.user))

  function select(state) {
    return state.user
  }

  let currentValue

  function handleChange() {
    const previousValue = currentValue
    currentValue = select(store.getState())

    if (previousValue !== currentValue) {
      setUser(currentValue)
    }
  }

  store.subscribe(handleChange)

  const subscribeToPlan = (paymentLink) => {
    window.location.href = `${paymentLink}?prefilled_email=${stripQuotes(user.username)}`
  }

  return (
    <div className="flex flex-col h-full w-full">
      <div className="p-4 m-4 border-slate-200 border-b">
        <h2 className="text-slate-500 font-bold">Your current subscription</h2>
        {returnPlanSummary(subscriptionLevels[user.subscription])}
      </div>

      <div className="flex p-4 space-x-4 justify-between max-w-[720px] mt-12">
        <div className="p-4 w-[20%]">
          <h1 className="text-slate-500 font-bold">Scan</h1>
          <p className="text-slate-400 text-xs">
            Scan one website per day, no access to advanced insights or document
            cleaning.
          </p>
          <div className="mt-12 mb-4">
            <span className="text-slate-500 text-xl font-bold">£9.99</span>{" "}
            <span className="text-xs text-slate-400">p/m</span>
          </div>
          {subscriptionLevels[user.subscription] ===
          subscriptionLevels.SCAN_ONLY ? (
            <button
              type="button"
              className="bg-green text-white px-4 py-2 text-xs rounded-sm font-bold bg-green-500"
            >
              Subscribed
            </button>
          ) : (
            <button
              type="button"
              onClick={() => subscribeToPlan(paymentLinks.SCAN_ONLY)}
              className="primary px-4 py-2 text-xs rounded-sm font-bold opacity-60"
            >
              Subscribe
            </button>
          )}
        </div>

        <div className="p-8 w-[30%] scale-125 hover:scale-150 transition-all bg-slate-100">
          <h1 className="text-slate-500 font-bold">Scan Pro & Clean</h1>
          <p className="text-slate-400 text-xs">
            Scan multiple websites per day, see advanced insights and clean all
            your documents.
          </p>
          <div className="mt-12 mb-4">
            <span className="text-slate-500 text-xl font-bold">£49.99</span>{" "}
            <span className="text-xs text-slate-400">per month</span>
          </div>
          {subscriptionLevels[user.subscription] ===
          subscriptionLevels.SCAN_PRO_CLEAN ? (
            <button
              type="button"
              className="bg-green text-white px-4 py-2 text-xs rounded-sm font-bold bg-green-500"
            >
              Subscribed
            </button>
          ) : (
            <button
              type="button"
              onClick={() => subscribeToPlan(paymentLinks.SCAN_PRO_CLEAN)}
              className="primary px-4 py-2 text-xs rounded-sm font-bold"
            >
              Subscribe
            </button>
          )}
        </div>

        <div className="p-4 w-[20%]">
          <h1 className="text-slate-500 font-bold">Scan Pro</h1>
          <p className="text-slate-400 text-xs">
            Scan multiple websites per day and see advanced insights with no
            access to cleaning.
          </p>
          <div className="mt-12 mb-4">
            <span className="text-slate-500 text-xl font-bold">£29.99</span>{" "}
            <span className="text-xs text-slate-400">p/m</span>
          </div>
          {subscriptionLevels[user.subscription] ===
          subscriptionLevels.SCAN_PRO ? (
            <button
              type="button"
              className="bg-green text-white px-4 py-2 text-xs rounded-sm font-bold bg-green-500"
            >
              Subscribed
            </button>
          ) : (
            <button
              type="button"
              onClick={() => subscribeToPlan(paymentLinks.SCAN_PRO)}
              className="primary px-4 py-2 text-xs rounded-sm font-bold opacity-60"
            >
              Subscribe
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../components/common/Button"
import Input from "../../components/common/Input"
import Loader from "../../components/common/Loader"
import { forgotPassword } from "../../api/auth/auth"
import Header from "../../components/common/Header"
import bg from "../../images/bg-1.png"

function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState("")
  const [successful, setSuccessful] = useState("")
  const [error, setError] = useState("")
  const { t } = useTranslation()
  const handlePasswordRecovery = async () => {
    setError(false)
    setLoading(true)
    const response = await forgotPassword({ email: username })
    setLoading(false)
    if (!response) {
      setError("Error occurred when attemping to reset password.")
      return
    }
    if (response.status === 200) {
      setSuccessful(response.data.message)
    } else {
      setError(response.data.message)
    }
  }
  return (
    <div className="h-full w-full flex flex-col">
      <div className="h-full grid grid-cols-12">
        <div className="col-span-4 h-full w-full">
          <Header />
        </div>
        <div
          style={{ backgroundImage: `url(${bg})` }}
          className="col-span-8 bg-cover bla h-full w-full"
        >
          <div className="space-y-8 mt-12 flex-grow">
            <div className="p-12 space-y-6 w-[540px] m-auto">
              <h1 className="text-slate-500 text-xl font-semibold">
                {t("forgotPassword")}
              </h1>
              <p className="text-slate-400 text-sm">
                {t("pleaseEnterYourEmailBelowToReset")}
              </p>
              {!loading ? (
                <>
                  <Input
                    size="large"
                    value={username}
                    onChange={(v) => setUsername(v)}
                    label="Username"
                    type="email"
                    placeholder={t("emailAddress")}
                  />
                  <Button
                    disabled={!username}
                    text={t("resetPassword")}
                    onClick={() => handlePasswordRecovery()}
                  />
                </>
              ) : (
                ""
              )}
              {successful ? (
                <span className="bg-green-100 text-green-700 p-4 mt-2 rounded-md">
                  {successful}
                </span>
              ) : (
                ""
              )}
              {error ? (
                <span className="bg-red-100 text-red-500 p-4 mt-2 rounded-md">
                  {error}
                </span>
              ) : (
                ""
              )}
              {loading ? <Loader /> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword

import config from "../../config/index"

const { API_ENDPOINT, APP_ID } = config
const getHeaders = () => {
  const token = localStorage.getItem("s:Token")
  const headers = {
    Authorization: token === null ? "" : `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  }
  return headers
}

export const updateUser = async (payload, email) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/users/update`, {
      method: "PUT",
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        application: APP_ID,
        user: {
          email,
          ...payload,
        },
      }),
    })

    if (!response.ok) {
      console.error("Bad Request:", response.status, response.statusText)
    } else {
      const responseData = await response.json()
      const updatedUserData = responseData.user
      // eslint-disable-next-line no-unused-vars
      const mergedUserData = { ...payload, ...updatedUserData }
    }
    return response
  } catch (error) {
    console.error("Error while updating user:", error)
    return error
  }
}

export const getUser = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}/users/get`, {
      method: "GET",
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      console.error("Bad Request:", response.status, response.statusText)
    } else {
      const responseData = await response.json()
      const updatedUserData = responseData.payload
      return updatedUserData
    }

    return response
  } catch (error) {
    console.error("Error while updating user:", error)
    return error
  }
}

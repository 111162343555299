import TimeAgo from "javascript-time-ago"

// English.
import en from "javascript-time-ago/locale/en"
import es from "javascript-time-ago/locale/es"

TimeAgo.addLocale(es)
TimeAgo.addDefaultLocale(en)

export const timeAgo = new TimeAgo("en-GB")
export const timeAgoEs = new TimeAgo("es-ES")

/* eslint-disable react/prop-types */
import React from "react"

export default function Input(props) {
  const {
    type,
    // eslint-disable-next-line no-unused-vars
    label,
    placeholder,
    value,
    onChange,
    className,
    size = "small",
  } = props
  const defaultStyle = "flex flex-row rounded-sm text-sm"

  return (
    <div className={`${className || defaultStyle}`}>
      <input
        value={value}
        onChange={(val) => onChange(val.currentTarget.value)}
        type={type}
        placeholder={placeholder}
        className={`${size === "small" ? "p-2 text-xs" : "p-4 text-sm"} border text-slate-400 rounded-md h-auto w-full focus:outline-none focus:ring-0 focus:border-gray-300`}
      />
    </div>
  )
}

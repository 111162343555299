import "./auth.css"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Input from "../../components/common/Input"
import Button from "../../components/common/Button"
import Loader from "../../components/common/Loader"
import { login } from "../../api/auth/auth"
import Header from "../../components/common/Header"
import { update } from "../../store/user"
import bg from "../../images/bg-1.png"
import splashBg from "../../images/logo-splash.png"

function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [username, setUsername] = useState("")
  const [error, setError] = useState()

  const dispatch = useDispatch()

  const loggedIn = useSelector((state) => state.user.token)

  if (loggedIn) {
    navigate("/dashboard")
  }

  const handleLogin = async () => {
    setError("")
    setLoading(true)
    const response = await login({ user: username, pass: password })
    setLoading(false)

    if (!response) {
      setError("Error when signing in, please try again")
      return
    }

    if (response.status === 200) {
      const token = response.data.payload?.id
      const usernameData = JSON.stringify(response.data.payload.email)

      localStorage.setItem("s:Token", token)
      localStorage.setItem("s:User", usernameData)

      dispatch(update({ username, token, ...response.data.payload }))

      navigate("/dashboard")
    } else {
      setError(
        !response.response
          ? response.data.message
          : response.response.data.message,
      )
    }
  }

  return (
    <div className="h-full w-full flex flex-col">
      <div
        style={{
          backgroundImage: `url(${splashBg})`,
          backgroundColor: "#0a161f",
        }}
        className="h-full bg-contain bg-no-repeat grid grid-cols-12"
      >
        <div className="col-span-4 h-full w-full">
          <Header />
        </div>
        <div
          style={{ backgroundImage: `url(${bg})` }}
          className="col-span-8 bg-cover bla h-full w-full"
        >
          <div className="space-y-8 mt-12 flex-grow">
            <div className="p-12 space-y-6 w-[540px] m-auto">
              <h1 className="text-slate-500 text-xl font-semibold">
                {t("login")}
              </h1>
              <p className="text-slate-400 text-sm">{t("loginText")}</p>
              <Input
                size="large"
                value={username}
                onChange={(v) => setUsername(v)}
                label="Username"
                type="text"
                placeholder={t("username")}
              />
              <Input
                size="large"
                value={password}
                onChange={(v) => setPassword(v)}
                label="Password"
                type="password"
                placeholder={t("password")}
              />
              <Button
                disabled={!username || !password}
                text={t("login")}
                onClick={() => handleLogin()}
              />
              {error ? (
                <div className="bg-red-100 text-red-500 text-sm p-4 rounded-md">
                  {error}
                </div>
              ) : (
                ""
              )}
              <div className="m-auto flex text-sm">
                <Link className="w-full mr-2" to="/forgot-password">
                  <button
                    type="button"
                    className="p-2 border text-slate-400 tertiary border-none rounded-md w-full"
                  >
                    {t("forgotPassword")}
                  </button>
                </Link>
                <Link className="w-full ml-2" to="/register">
                  <button
                    type="button"
                    className="p-2 shadow text-slate-400 bg-white rounded-md w-full"
                  >
                    {t("register")}
                  </button>
                </Link>
              </div>
              {loading ? <Loader /> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login

import axios from "axios"
import config from "../../config/index"
import store from "../../store"
import { update } from "../../store/user"

const { API_ENDPOINT, APP_ID } = config

const payloadWithId = (payload) => ({ id_app: APP_ID, ...payload })

const getHeaders = () => {
  const token = localStorage.getItem("s:Token")

  const headers = {
    Authorization: token === null ? "" : `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  }

  return headers
}

export const login = async (payload) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/auth/login`,
      payloadWithId(payload),
      { ...getHeaders() },
    )

    store.dispatch(update(response.payload))

    return response
  } catch (error) {
    console.log("Error while logging in", error)
    return error
  }
}

export const register = async (payload) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}/users/create`, {
      registration: { applicationId: APP_ID },
      user: { ...payload },
    })
    return response
  } catch (error) {
    console.log("Error while registering user", error)
    return error
  }
}

export const forgotPassword = async (payload) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/users/credentials/forgot_password`,
      { applicationId: APP_ID, ...payload },
    )
    return response
  } catch (error) {
    console.log("Error while resetting password", error)
    return error
  }
}

export const logout = async () => {
  try {
    await localStorage.removeItem("s:Token")
    await localStorage.removeItem("s:User")
    return true
  } catch (error) {
    console.log("Error when logging out")
    return false
  }
}

export const isLoggedIn = async () => {
  const token = localStorage.getItem("s:Token")

  console.log("user is logged in", isLoggedIn)

  if (!token) {
    return false
  }
  return token
}

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { MapTrifold } from "@phosphor-icons/react"
import { DndProvider, MultiBackend } from "react-dnd-multi-backend"
import { useTranslation } from "react-i18next"
import DraggableGridItem from "../../utils/grid/DraggableGridItem"
import HTML5toTouch from "../../utils/grid/HTML5toTouch"
import { LocationsCard } from "../../components/cards/LocationsCard"
import { getLatestDomains } from "../../api/results/results"

function Locations() {
  const { t } = useTranslation()
  const initialItems = [
    {
      id: 1,
      index: 1,
      title: "Locations",
      content: <LocationsCard />,
      icon: <MapTrifold size={16} />,
    },
  ]

  const [list, setList] = useState(initialItems)
  const [domains, setDomains] = useState([])
  const onDrop = () => {}
  const getDomains = async () => {
    try {
      const response = await getLatestDomains()
    } catch (error) {
      throw new Error("Error retrieving domains", error)
    }
  }

  useEffect(() => {
    const domainsData = getDomains()
    setDomains(domainsData)
  }, [])

  return (
    <div className="flex flex-grow w-full h-full p-4">
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        {list.map((item) => (
          <DraggableGridItem
            key={item.id}
            item={item}
            onDrop={onDrop}
            title={t("locations")} // was item.title
            icon={item.icon}
            fullWidth
          >
            {item.content}
          </DraggableGridItem>
        ))}
      </DndProvider>
    </div>
  )
}

export default Locations

/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState } from "react"
import { ArrowSquareIn, Buildings } from "@phosphor-icons/react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Button from "../common/Button"
import { timeAgo, timeAgoEs } from "../../utils/dates/dates"
import { createAsset, searchAssets } from "../../api/assets/assets"
import Modal from "../common/Modal"
import Input from "../common/Input"
import Loader from "../common/Loader"
import { createScan } from "../../api/scans/scans"
import store from "../../store"

function NoAssets() {
  const { t } = useTranslation()
  return (
    <div className="flex w-full h-full justify-center items-center">
      <h2 className="text-gray-500 font-bold text-md w-full text-center px-8">
        {t("noAssetsWereFound")}
      </h2>
    </div>
  )
}

function AssetItem(props) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {
    org,
    scan,
    tld_norm,
    // n_aws,
    // n_ftp,
    // n_tld,
    // org_enabled,
    org_id,
    tld_id,
  } = props

  //   const totalAssetCount = n_aws + n_ftp + n_tld
  // eslint-disable-next-line no-nested-ternary
  const lastActive = scan
    ? scan.scan_date_added
      ? timeAgo.format(Date.parse(scan.scan_date_added))
      : t("noActivity")
    : t("noActivity")
  // eslint-disable-next-line no-nested-ternary
  const lastActiveEs = scan
    ? scan.scan_date_added
      ? timeAgoEs.format(Date.parse(scan.scan_date_added))
      : t("noActivity")
    : t("noActivity")
  const updatedLastActive =
    i18n.resolvedLanguage === "es" ? lastActiveEs : lastActive
  // const enabled = org_enabled ? t('active') : t('disabled');
  // const sample = org_enabled ? t('sample') : t('live');

  const launchScan = async (assetId) => {
    setLoading(true)
    try {
      // eslint-disable-next-line no-unused-vars
      const scanData = await createScan(assetId)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const goToAssets = (assetId) => {
    navigate(`/dashboard/assets/${assetId}`)
  }

  return (
    <tr
      className={`${loading ? "opacity-30 cursor-not-allowed" : ""} hover:bg-slate-50 text-xs text-gray-500 p-4 border-b border-gray-100 w-full`}
      key={org_id}
    >
      <td className="w-40 text-left">
        <div
          tabIndex={0}
          role="button"
          onKeyDown={(e) => {
            if (e.key === "Enter") goToAssets(tld_id)
          }}
          onClick={() => goToAssets(tld_id)}
          className="font-bold cursor-pointer text-slate-500 flex"
        >
          {tld_norm} <ArrowSquareIn size={16} />
        </div>
        <div>
          {org?.org_name} ({org?.org_group})
        </div>
      </td>
      <td>
        <div>{updatedLastActive}</div>
      </td>
      <td className="text-center">{scan?.stats_n_docs_valid_total || 0}</td>
      <td className="text-center">{scan?.stats_n_tags_valid_total || 0}</td>
      <td className="text-center">{scan?.stats_n_values_valid_total || 0}</td>
      <td>
        {loading ? (
          <Loader />
        ) : (
          <button
            type="button"
            onClick={() => launchScan(tld_id)}
            className="bg-slate-100 rounded-md px-4 py-2"
          >
            {t("launchScan")}
          </button>
        )}
      </td>
    </tr>
  )
}

export default function AssetsCard() {
  const initialAssets = useSelector((state) => state.assets)
  const initialOrganisations = useSelector((state) => state.organisations)
  const { t } = useTranslation()
  const [assets, setAssets] = useState(initialAssets)
  const [organisations, setOrganisations] = useState(initialOrganisations)
  const [selectedOrganisation, setSelectedOrganisation] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [domain, setDomain] = useState("")
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [isValidDomain, setValidDomain] = useState(true)
  let currentAssetsValue
  function select(state) {
    return state
  }
  // eslint-disable-next-line no-unused-vars
  function handleChange() {
    const previousAssetsValue = currentAssetsValue
    currentAssetsValue = select(store.getState())
    if (previousAssetsValue !== currentAssetsValue) {
      setAssets(currentAssetsValue.assets)
      setOrganisations(currentAssetsValue.organisations)
    }
  }
  // const unsubscribe = store.subscribe(handleChange)
  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleFormSubmit = async () => {
    setModalLoading(true)
    const match = domain.match(
      /^((https?|http):\/\/)?([a-z0-9.-]+)\.([a-z]{2,})(:\d{1,5})?(\/[^?#]*)?(\\?[^#]*)?(#(.*))?$/i,
    )
    try {
      if (!match || match[3] === undefined) {
        setValidDomain(false)
        setModalLoading(false)
        return
      }
      setValidDomain(true)
      // eslint-disable-next-line no-unused-vars
      const response = await createAsset({ domain, selectedOrganisation })
      setModalLoading(false)
      closeModal()
    } catch (error) {
      setModalLoading(false)
    }
  }

  //   const startFirstScan = async (assetId) => {
  //         const response = await createScan(assetId)
  //     }

  const handleAssetSearch = async (term) => {
    if (term.length < 3) return
    setLoading(true)
    setAssets([])
    const getAssets = await searchAssets(term)
    setAssets(getAssets)
    setLoading(false)
  }

  return (
    <div className="flex flex-col h-full w-full relative pb-[35px]">
      <Input
        placeholder={t("searchAssets")}
        className="m-4"
        onChange={handleAssetSearch}
      />
      <div className="flex flex-col w-full flex-grow overflow-scroll pb-[35px]">
        {assets.length ? (
          <table cellPadding="10">
            <tr className="text-xs text-gray-500 text-left bg-slate-50">
              <th>{t("organisation")}</th>
              <th>{t("latestScan")}</th>
              <th className="text-center">{t("docs")}</th>
              <th className="text-center">{t("tags")}</th>
              <th className="text-center">{t("values")}</th>
              <th />
            </tr>
            {assets.map((o) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <AssetItem key={o.org_id} {...o} />
            ))}
          </table>
        ) : (
          <NoAssets />
        )}
      </div>
      <div className="shadow-inner absolute bottom-[35px] bg-white w-full">
        <button
          type="button"
          onClick={openModal}
          className="p-2 text-xs text-gray-400 w-full text-center hover:bg-slate-50 transition-all hover:animate-pulse"
        >
          + {t("addNewAsset")}
        </button>
      </div>
      <Modal
        title={t("addNewAsset")}
        icon={<Buildings size={16} />}
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        {!modalLoading ? (
          <div className="p-4 space-y-4">
            <div className="space-y-4">
              <select
                onChange={(v) => setSelectedOrganisation(v.currentTarget.value)}
                className="p-2 border rounded-md h-auto w-full text-xs focus:outline-none focus:ring-0 focus:border-gray-400"
              >
                <option value={0}>{t("selectOrganisation")}</option>
                {organisations.map((o) => (
                  <option key={o.org_id} value={o.org_id}>
                    {o.org_name}
                  </option>
                ))}
              </select>
              <Input
                onChange={(e) => setDomain(e)}
                required
                id="domain"
                type="text"
                placeholder={t("enterDomain")}
              />
              {!isValidDomain ? (
                <span className="bg-red-100 text-red-500 p-2 text-xs mt-2 rounded-md">
                  {t("pleaseEnterAValidDomain")}
                </span>
              ) : null}
            </div>
            <Button
              disabled={!domain || selectedOrganisation === 0}
              onClick={handleFormSubmit}
              text={t("createAsset")}
            />
          </div>
        ) : (
          <Loader variant="dark" />
        )}
      </Modal>
    </div>
  )
}

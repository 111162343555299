import axios from "axios"
import config from "../../config/index"

const { API_ENDPOINT, APP_ID } = config

const payloadWithId = (payload) => ({ id_app: APP_ID, ...payload })

const getHeaders = () => {
  const token = localStorage.getItem("s:Token")

  const headers = {
    Authorization: token === null ? "" : `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  }
  return headers
}

const getCleanHeaders = () => {
  const token = localStorage.getItem("s:Token")

  const headers = {
    Authorization: token === null ? "" : `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
    Accept: "application/octet-stream",
  }

  return headers
}

export const scanFile = async (payload) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/demo/file/view`,
      payloadWithId(payload),
      { headers: getHeaders() },
    )
    return response
  } catch (error) {
    console.log("Error while scanning file", error)
    return error
  }
}

export const cleanFile = async (payload) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/demo/file/wipe`,
      payloadWithId(payload),
      { headers: getCleanHeaders() },
    )
    return response
  } catch (error) {
    console.log("Error while cleaning file", error)
    return error
  }
}

export const scanDomain = async (domain) => {
  try {
    const response = await axios.get(
      `${API_ENDPOINT}/api/v1/assets/search?name=${domain}`,
    )
    return response
  } catch (error) {
    console.log("Error while scanning domains", error)
    return error
  }
}

import { uniq } from "lodash"
import config from "../../config/index"
import store from "../../store/index"
import { update } from "../../store/groups"
import { update as updateThreats } from "../../store/threats"
import { mapMetagroupNames } from "../../utils"

const { API_ENDPOINT } = config

const getHeaders = () => {
  const token = localStorage.getItem("s:Token")
  const headers = {
    Authorization: token === null ? "" : `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  }
  return headers
}

export const getReadFileTypes = async () => {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/settings/get/filetypes/read`,
      {
        method: "GET",
        headers: getHeaders(),
      },
    )
    if (!response.ok) {
      throw new Error(
        `Error while retrieving filetypes: ${response.statusText}`,
      )
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.log("Error while retrieving filetypes", error)
    return error
  }
}

export const getGroups = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}/settings/get/groups`, {
      method: "GET",
      headers: getHeaders(),
    })

    if (!response.ok) {
      throw new Error(`Error while retrieving groups: ${response.statusText}`)
    }
    const data = await response.json()
    data.payload = mapMetagroupNames(data.payload)
    const groupsArray = []
    Object.keys(data.payload).forEach((key) => {
      groupsArray.push(data.payload[key])
    })

    store.dispatch(update(uniq(groupsArray)))
    return data
  } catch (error) {
    console.log("Error while retrieving groups", error)
    return error
  }
}

export const getThreats = async () => {
  try {
    const response = await fetch(`${API_ENDPOINT}/settings/get/threats`, {
      method: "GET",
      headers: getHeaders(),
    })
    if (!response.ok) {
      throw new Error(`Error while retrieving threats: ${response.statusText}`)
    }
    const data = await response.json()
    const threatsArray = []
    Object.keys(data.payload).forEach((key) => {
      threatsArray.push(data.payload[key])
    })
    store.dispatch(updateThreats(threatsArray))
    return data
  } catch (error) {
    console.log("Error while retrieving threats", error)
    return error
  }
}

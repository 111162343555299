/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  ArrowUDownLeft,
  Buildings,
  FloppyDiskBack,
  MapTrifold,
  Radioactive,
  Scan,
} from "@phosphor-icons/react"
import { useSelector } from "react-redux"
import Toggle from "react-toggle"
import { useTranslation } from "react-i18next"
import Button from "../../components/common/Button"
import { activateAsset, deactivateAsset } from "../../api/assets/assets"

const mockScore = Math.floor(Math.random() * 100) + 1

function Asset() {
  const { id } = useParams()
  const { t } = useTranslation()
  const asset = useSelector((state) =>
    state.assets.filter((a) => a.tld_id === id),
  )[0]
  const [isEnabled, setIsEnabled] = useState(asset.tld_is_enabled)
  const toggleAsset = async () => {
    if (isEnabled) {
      const response = await deactivateAsset(asset.tld_id)
      setIsEnabled(false)
    } else {
      const response = await activateAsset(asset.tld_id)
      setIsEnabled(true)
    }
  }
  const navigate = useNavigate()
  const goToOrganisation = (orgId) => {
    navigate(`/dashboard/organisations/${orgId}`)
  }

  const totalScore = asset.scan?.length >= 1 ? mockScore + 1 : 0
  return (
    <div className="flex flex-col flex-grow w-full h-full p-4 space-y-2">
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="flex uppercase text-xs text-slate-400"
        >
          <ArrowUDownLeft className="p-1" size={18} />
          {t("back")}
        </button>
      </div>
      <div>
        <h1 className="text-lg text-slate-600 flex items-center space-x-2">
          <FloppyDiskBack /> <span>{asset.tld_user}</span>
        </h1>
      </div>
      <hr />
      <div className="grid grid-cols-12 space-x-4">
        <div className="col-span-9 space-y-4">
          <div className="flex text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-x-4 items-start">
            <div className="h-[120px] w-[95px]">
              <h2 className="text-center text-slate-600">
                {t("overallScore")}
              </h2>
              <div className={`circle percentage-${totalScore} scale-150 p-4`}>
                <span>{totalScore}</span>
                <div className="percentage-bar" />
              </div>
            </div>
            <div className="flex flex-col h-full text-center">
              <h2 className="text-center mb-10 text-slate-600">
                {t("totalThreats")}
              </h2>
              <span className="text-[60px]">-</span>
            </div>
            <div className="flex flex-col h-full text-center">
              <h2 className="text-center mb-10 text-slate-600">
                {t("totalScans")}
              </h2>
              <span className="text-[60px]">-</span>
            </div>
            <div className="flex flex-col h-full text-center">
              <h2 className="text-center mb-10 text-slate-600">
                {t("activeScans")}
              </h2>
              <span className="text-[60px]">0</span>
            </div>
            <div className="flex flex-col h-full text-center">
              <h2 className="text-center mb-10 text-slate-600">
                {t("documentsFound")}
              </h2>
              <span className="text-[60px]">-</span>
            </div>
          </div>
          <div className="flex flex-col text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-y-2 items-start">
            <h1 className="text-lg text-slate-600 flex items-center space-x-2">
              <Buildings />
              <span>{t("organisation")}</span>
            </h1>
            <div className="flex flex-col">
              <span
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") goToOrganisation(asset.org.org_id)
                }}
                onClick={() => goToOrganisation(asset.org.org_id)}
                className="font-bold text-slate-500 cursor-pointer"
              >
                {asset.tld_user}
              </span>
            </div>
          </div>
          <div className="flex flex-col text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-y-2 items-start justify-start">
            <h1 className="text-lg text-slate-600 flex items-center space-x-2">
              <Scan /> <span>{t("scans")}</span>
            </h1>
            <span>{t("noScanDataFound")}</span>
          </div>
          <div className="flex flex-col text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-y-2 items-start">
            <h1 className="text-lg text-slate-600 flex items-center space-x-2">
              <Radioactive />
              <span>{t("threats")}</span>
            </h1>
            <span>{t("noThreatDataFound")}</span>
          </div>
          <div className="flex flex-col text-xs justify-between shadow-md p-4 text-slate-400 bg-white space-y-2 items-start">
            <h1 className="text-lg text-slate-600 flex items-center space-x-2">
              <MapTrifold />
              <span>{t("locations")}</span>
            </h1>
            <span>{t("noLocationDataFound")}</span>
          </div>
        </div>
        <div className="col-span-3 space-y-4">
          <div className="flex flex-col space-y-4">
            <span className="text-slate-600 text-xs">
              {t("enableDisableAsset")}
            </span>
            <Toggle
              checked={isEnabled}
              name="organisationEnabled"
              value
              onChange={toggleAsset}
            />
          </div>
          <hr />
          <div className="flex flex-col space-y-4">
            <span className="text-slate-600 text-xs">
              {t("enableDisableMonitoring")}
            </span>
            <Toggle
              disabled
              checked={asset.tld_is_monitored}
              name="organisationEnabled"
              value
              onChange={toggleAsset}
            />
          </div>
          <Button disabled primary={false} text={t("deleteAsset")} />
        </div>
      </div>
    </div>
  )
}

export default Asset

import { createSlice } from "@reduxjs/toolkit"

export const userSlice = createSlice({
  name: "user",
  initialState: {
    username: localStorage.getItem("s:User"),
    token: localStorage.getItem("s:Token"),
    subscription: undefined,
  },
  reducers: {
    update: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state = { ...action.payload }
      return state
    },
  },
})

export const { update } = userSlice.actions

export default userSlice.reducer

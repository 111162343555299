/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import logo from "../../images/logo-light.svg"
import { logout } from "../../api/auth/auth"
import { update } from "../../store/user"
import Footer from "./Footer"

const lngs = {
  en: { nativeName: "EN" },
  es: { nativeName: "ES" },
}

function Header({ showUpload = true }) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const handleLogout = async () => {
    const response = await logout()
    if (response) {
      dispatch(update({}))
      navigate("/")
    } else {
      // Didn't logout so do nothing?
    }
  }
  return (
    <div className="flex flex-col flex-grow h-full p-20 justify-between">
      <img
        onClick={() => {
          navigate("/")
        }}
        className="w-52 h-auto z-10 cursor-pointer"
        alt=""
        src={logo}
      />
      {showUpload ? (
        <div className="space-y-6">
          <h2 className="text-white text-lg font-bold">
            {t("uncoverDataVulnerabilities")}
          </h2>
          <p className="text-white opacity-80 text-sm">
            {t(`uncoverDataVulnerabilitiesText`)}
          </p>
          <button
            type="button"
            onClick={() => {
              navigate("/explore")
            }}
            className="bg-transparent border-white border border-opacity-70 text-white px-4 py-2 text-sm rounded-md"
          >
            {t("uploadDocument")}
          </button>
        </div>
      ) : (
        ""
      )}
      <nav className="text-sm flex flex-wrap flew-grow w-full">
        {Object.keys(lngs).map((lng) => (
          <button
            key={lng}
            className={`${i18n.resolvedLanguage === lng ? "rounded-md border bg-blend-overlay  p-2 font-bold text-xs text-white" : "rounded-md p-2 font-bold text-xs text-white"}`}
            type="submit"
            onClick={() => i18n.changeLanguage(lng)}
          >
            {lngs[lng].nativeName}
          </button>
        ))}
        <Footer />
      </nav>
    </div>
  )
}

export default Header

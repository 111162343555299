import React, { useState } from "react"

import { FloppyDiskBack } from "@phosphor-icons/react"
import { DndProvider, MultiBackend } from "react-dnd-multi-backend"
import { useTranslation } from "react-i18next"
import DraggableGridItem from "../../utils/grid/DraggableGridItem"

import HTML5toTouch from "../../utils/grid/HTML5toTouch"
import AssetsCard from "../../components/cards/AssetsCard"

function Assets() {
  const { t } = useTranslation()
  const initialItems = [
    {
      id: 1,
      index: 1,
      title: t("assets"),
      content: <AssetsCard />,
      icon: <FloppyDiskBack size={16} />,
    },
  ]
  // eslint-disable-next-line no-unused-vars
  const [list, setList] = useState(initialItems)
  const onDrop = () => {}
  return (
    <div className="flex flex-grow w-full h-full p-4">
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        {list.map((item) => (
          <DraggableGridItem
            key={item.id}
            item={item}
            onDrop={onDrop}
            title={item.title}
            icon={item.icon}
            fullWidth
          >
            {item.content}
          </DraggableGridItem>
        ))}
      </DndProvider>
    </div>
  )
}

export default Assets

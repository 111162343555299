/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { Minus, Plus, Warning } from "@phosphor-icons/react"
import React, { useState } from "react"
import StepWizard from "react-step-wizard"
import { FileUploader } from "react-drag-drop-files"
import { useTranslation } from "react-i18next"
import Header from "../../components/common/Header"
import bg from "../../images/bg-1.png"
import Loader from "../../components/common/Loader"
import { cleanFile, scanFile } from "../../api/demo/demo"
import Button from "../../components/common/Button"
import splashBg from "../../images/logo-splash.png"

function camelCaseToWords(s) {
  const result = s.replace(/([A-Z])/g, " $1")
  return result.charAt(0).toUpperCase() + result.slice(1)
}

function SeriousDocumentMetadataItem({ value }) {
  const { t } = useTranslation()
  const returnWeightedBackground = (weight) => {
    switch (weight) {
      case 0:
        return "bg-slate-300"
      case 1:
        return "bg-yellow-400"
      case 5:
        return "bg-orange-400"
      case 7:
        return "bg-red-400"
      case 10:
        return "bg-red-600"
      default:
        return "bg-red-600"
    }
  }
  return (
    <div className="flex flex-row text-xs py-2 space-x-2 mt-2">
      <div
        className={`${returnWeightedBackground(value.pc_group_max_score)} rounded-full p-2 text-white`}
      >
        <Warning className="drop-shadow-md" size={16} />
      </div>
      <div>
        <span className="text-slate-500">
          <span className="font-bold text-slate-600">{t("type")}:</span>
          {value.pc_group_name}
        </span>
        <span className="line-clamp-1 text-slate-500">
          <span className="font-bold text-slate-600">{t("exposedValue")}:</span>
          {value.value}
        </span>
      </div>
    </div>
  )
}

function DocumentMetadataItem({ name, value }) {
  const label = camelCaseToWords(name.toString().split(":")[1])
  return (
    <div key={name} className="flex flex-col text-xs py-2">
      <span className="text-slate-400 font-bold">{label}</span>
      <span className="line-clamp-1 text-slate-300">{value}</span>
    </div>
  )
}

function Nav(props) {
  const dots = []
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i
    dots.push(
      <span key={`step-${i}`} className={`dot ${isActive ? "active" : ""}`}>
        &bull;
      </span>,
    )
  }
  return <div className="nav">{dots}</div>
}

function DocumentUpload(props) {
  const { t } = useTranslation()
  const scanDocument = async (file) => {
    // Move to next stage
    props.nextStep()
    props.setFile(file)
    try {
      const response = await scanFile({ file })
      if (response.status !== 200) {
        setTimeout(() => {
          props.previousStep()
        }, 1000)
      } else {
        setTimeout(() => {
          props.onUpload(response.data.payload)
          props.nextStep()
        }, 2000)
      }
      // Now I have the results, move to the next summary stage.
    } catch (error) {
      props.lastStep()
      // Move to previous stage
    }
  }
  return (
    <div className="space-y-6">
      <h1 className="text-slate-500 text-xl font-semibold">
        {t("uploadYourDocument")}
      </h1>
      <p className="text-slate-400 text-sm">{t("uploadYourDocumentText")}</p>
      <FileUploader
        label={t("fileUploaderLabel")}
        handleChange={scanDocument}
        multiple={false}
        classes="file-upload mt-4 mb-4"
        name="file"
        maxSize=""
      />
    </div>
  )
}

function Scanning() {
  const { t } = useTranslation()
  return (
    <div className="space-y-6">
      <h1 className="text-slate-500 text-xl font-semibold">
        {t("scanningYourDocument")}
      </h1>
      <p className="text-slate-400 text-sm">{t("scanningYourDocumentText")}</p>
      <Loader center={false} />
    </div>
  )
}

function Cleaning() {
  const { t } = useTranslation()
  return (
    <div className="space-y-6">
      <h1 className="text-slate-500 text-xl font-semibold">
        {t("cleaningYourDocument")}
      </h1>
      <p className="text-slate-400 text-sm">{t("cleaningYourDocumentText")}</p>
      <Loader center={false} />
    </div>
  )
}

function Results({
  mdCount,
  relevantCount,
  seriousBreaches,
  breaches,
  nextStep,
  cleanDocument,
  lastStep,
  previousStep,
}) {
  const breachesKeys = Object.keys(breaches || {})
  const seriousBreachesKeys = Object.keys(seriousBreaches || {})
  const { t } = useTranslation()
  const [breachesExpanded, setBreachesExpanded] = useState(false)
  const [seriousBreachesExpanded, setSeriousBreachesExpanded] = useState(true)
  const handleCleanDocument = () => {
    nextStep()
    const cleaned = cleanDocument()
    if (cleaned) {
      setTimeout(() => {
        lastStep()
      }, 1500)
    } else {
      previousStep()
    }
  }

  return (
    <div className="space-y-6">
      <h1 className="text-slate-500 text-xl font-semibold">
        {t("whatWeFound")}
      </h1>
      <p className="text-slate-400 text-sm">
        {t("weHaveIdentified")} {mdCount} {t("potentiallySeriousBreaches")},{" "}
        {relevantCount
          ? `${t("including")} ${relevantCount} ${t("highlySignificant")} ${relevantCount === 1 ? t("breach") : t("breaches")}.`
          : t("althoughWeDidNotFindAnySeriousBreachesOfData")}
      </p>
      <div className="shadow-md bg-white p-4">
        <div className="flex">
          <h2 className="text-slate-500 flex-grow text-md font-semibold">
            {t("significantBreaches")}
            <span className="text-slate-300 font-normal">
              ({Object.keys(seriousBreaches).length})
            </span>
          </h2>
          <button
            type="button"
            onClick={() => setSeriousBreachesExpanded(!seriousBreachesExpanded)}
          >
            {seriousBreachesExpanded ? (
              <Minus className="text-slate-300" size={16} />
            ) : (
              <Plus className="text-slate-300" size={16} />
            )}
          </button>
        </div>
        {seriousBreachesExpanded
          ? seriousBreachesKeys?.map((key) => (
              <SeriousDocumentMetadataItem
                name={key}
                value={seriousBreaches[key]}
              />
            ))
          : ""}
      </div>
      <div className="shadow-md bg-white p-4">
        <div className="flex">
          <h2 className="text-slate-500 flex-grow text-md font-semibold">
            {t("otherPotentialBreaches")}
            <span className="text-slate-300 font-normal">
              ({Object.keys(breaches).length})
            </span>
          </h2>
          <button
            type="button"
            onClick={() => setBreachesExpanded(!breachesExpanded)}
          >
            {breachesExpanded ? (
              <Minus className="text-slate-300" size={16} />
            ) : (
              <Plus className="text-slate-300" size={16} />
            )}
          </button>
        </div>
        {breachesExpanded
          ? breachesKeys?.map((key) => (
              <DocumentMetadataItem name={key} value={breaches[key]} />
            ))
          : ""}
      </div>
      <Button
        onClick={() => handleCleanDocument()}
        text={t("cleanYourDocument")}
      />
    </div>
  )
}

function RegisterToClean() {
  const { t } = useTranslation()
  return (
    <div className="p-4 border border-red-500 flex justify-center items-center space-x-4 bg-white">
      <div>
        <Warning className="text-red-500" size={32} />
      </div>
      <div>
        <h3 className="text-slate-500 font-bold">Oops!</h3>
        <p className="text-slate-400 text-sm">
          {t("toDownloadYourCleanedFileYouWillNeedTo")}
          <a> {t("registerAnAccount")}</a>
          {t("or")} <a>{t("login").toLowerCase()}</a>.
        </p>
      </div>
    </div>
  )
}

function CleanedResults({ seriousBreaches, breaches, firstStep }) {
  const { t } = useTranslation()
  const breachesKeys = Object.keys(breaches || {})
  const seriousBreachesKeys = Object.keys(seriousBreaches || {})
  const [breachesExpanded, setBreachesExpanded] = useState(false)
  const [seriousBreachesExpanded, setSeriousBreachesExpanded] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("s:Token"))
  const downloadDocument = () => {}
  return (
    <div className="space-y-6">
      <h1 className="text-slate-500 text-xl font-semibold">
        {t("documentSuccessfullyCleaned")}
      </h1>
      <p className="text-slate-400 text-sm">
        {t("weHaveSuccessfullyCleanedText")}
      </p>
      <div className="shadow-md bg-white p-4">
        <div className="flex">
          <h2 className="text-slate-500 flex-grow text-md font-semibold">
            {t("significantBreaches")}
            <span className="text-slate-300 font-normal">
              ({Object.keys(seriousBreaches).length})
            </span>
          </h2>
          <button
            type="button"
            onClick={() => setSeriousBreachesExpanded(!seriousBreachesExpanded)}
          >
            {seriousBreachesExpanded ? (
              <Minus className="text-slate-300" size={16} />
            ) : (
              <Plus className="text-slate-300" size={16} />
            )}
          </button>
        </div>
        {seriousBreachesExpanded
          ? seriousBreachesKeys?.map((key) => (
              <SeriousDocumentMetadataItem
                name={key}
                value={seriousBreaches[key]}
              />
            ))
          : ""}
      </div>
      <div className="shadow-md bg-white p-4">
        <div className="flex">
          <h2 className="text-slate-500 flex-grow text-md font-semibold">
            {t("otherPotentialBreaches")}
            <span className="text-slate-300 font-normal">
              ({Object.keys(breaches).length})
            </span>
          </h2>
          <button
            type="button"
            onClick={() => setBreachesExpanded(!breachesExpanded)}
          >
            {breachesExpanded ? (
              <Minus className="text-slate-300" size={16} />
            ) : (
              <Plus className="text-slate-300" size={16} />
            )}
          </button>
        </div>
        {breachesExpanded
          ? breachesKeys?.map((key) => (
              <DocumentMetadataItem name={key} value={breaches[key]} />
            ))
          : ""}
      </div>
      {loggedIn ? (
        <Button
          onClick={() => downloadDocument()}
          text={t("downloadYourDocument")}
        />
      ) : (
        <RegisterToClean />
      )}
      {loggedIn ? (
        <Button onClick={() => firstStep()} text={t("uploadAnotherDocument")} />
      ) : (
        ""
      )}
    </div>
  )
}

function Explore({ isInternal = false }) {
  const [mdCount, setMdCount] = useState(0)
  const [relevantCount, setRelevantCount] = useState(0)
  const [seriousBreaches, setSeriousBreaches] = useState([])
  const [breaches, setBreaches] = useState([])
  const [file, setFile] = useState(null)
  const [mdCountClean, setMdCountClean] = useState(0)
  const [relevantCountClean, setRelevantCountClean] = useState(0)
  const [seriousBreachesClean, setSeriousBreachesClean] = useState([])
  const [breachesClean, setBreachesClean] = useState([])
  const [cleanedFile, setCleanedFile] = useState(null)

  const onUpload = (response) => {
    setMdCount(Object.keys(response.md).length)
    setRelevantCount(Object.keys(response.md_relevant).length)
    setBreaches(response.md)
    setSeriousBreaches(response.md_relevant)
  }

  const cleanDocument = async () => {
    try {
      const response = await cleanFile({ file })
      setCleanedFile(response.data)
      return true
    } catch (error) {
      console.log("Error when cleaning file", error)
      return false
    }
  }
  return (
    <div className="h-full w-full flex flex-col overflow-scroll">
      <div
        style={{
          backgroundImage: isInternal ? "" : `url(${splashBg})`,
          backgroundColor: isInternal ? "" : "#0a161f",
        }}
        className="h-full bg-contain bg-no-repeat grid grid-cols-12"
      >
        {!isInternal ? (
          <div className="col-span-4 h-full w-full">
            <Header showUpload={false} />
          </div>
        ) : (
          ""
        )}
        <div
          style={{ backgroundImage: !isInternal ? `url(${bg})` : "none" }}
          className="col-span-8 bg-cover bla h-full w-full"
        >
          <div className={!isInternal ? `space-y-8 mt-12 flex-grow` : ""}>
            <div
              className={
                !isInternal ? `p-12 space-y-6 w-[540px] m-auto` : `p-12`
              }
            >
              <StepWizard nav={<Nav />}>
                <DocumentUpload setFile={setFile} onUpload={onUpload} />
                <Scanning />
                <Results
                  cleanDocument={cleanDocument}
                  mdCount={mdCount}
                  relevantCount={relevantCount}
                  seriousBreaches={seriousBreaches}
                  breaches={breaches}
                />
                <Cleaning />
                <CleanedResults
                  mdCount={mdCountClean}
                  relevantCount={relevantCountClean}
                  seriousBreaches={seriousBreachesClean}
                  breaches={breachesClean}
                />
              </StepWizard>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Explore
